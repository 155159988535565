var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('a-breadcrumb',[_c('a-breadcrumb-item',{attrs:{"href":""}},[_c('a-icon',{attrs:{"type":"home"}})],1),_c('a-breadcrumb-item',{attrs:{"href":""}},[_c('a-icon',{attrs:{"type":"user"}}),_c('span',[_vm._v("监控管理")])],1),_c('a-breadcrumb-item',[_vm._v(" 设备管理 ")])],1),_c('div',{staticClass:"search-list-box"},[_c('a-form',{staticClass:"ant-advanced-search-form",attrs:{"form":_vm.form},on:{"submit":_vm.handleSearch}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":6}},[_c('a-form-item',{attrs:{"label":"设备型号："}},[_c('a-input',{attrs:{"placeholder":"请输入设备编号"}})],1)],1),_c('a-col',{style:({
            textAlign: 'left',
            paddingLeft: '35px',
            marginTop: '6px',
          }),attrs:{"span":6}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 查询 ")]),_c('a-button',{style:({ marginLeft: '8px' }),on:{"click":_vm.handleReset}},[_vm._v(" 重置 ")])],1)],1)],1),_c('div',{staticClass:"search-result-list"},[_c('div',{staticClass:"table-header-box"},[_vm._m(0),_c('div',{staticClass:"right"},[_c('a-button',{staticClass:"marginr20",attrs:{"type":"primary","html-type":"submit"},on:{"click":function($event){_vm.show2 = true}}},[_vm._v(" 新增 ")]),_c('a-button',{staticClass:"marginr20",attrs:{"type":"primary","html-type":"submit"},on:{"click":function($event){_vm.show2 = true}}},[_vm._v(" 修改 ")])],1)]),_c('a-table',{attrs:{"row-selection":_vm.rowSelection,"columns":_vm.columns,"data-source":_vm.data,"scroll":{ x: 2200, y: 300 },"show-total":function (total) { return ("Total " + total + " items"); },"size":"small"}})],1)],1),_c('a-modal',{attrs:{"title":"维修信息","width":"800px","footer":null},on:{"ok":_vm.handleOk},model:{value:(_vm.show2),callback:function ($$v) {_vm.show2=$$v},expression:"show2"}},[_c('a-form',{staticClass:"fromdisflex",staticStyle:{"margin":"0 auto","width":"90%"},attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":24}},[_c('a-form-item',{attrs:{"label":"设备型号："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}],staticStyle:{"width":"400px"}})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"纸币入钞箱："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"纸币找零箱(100元)："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"纸币找零箱(50元)："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"纸币找零箱(20元)："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"纸币找零箱(10元)："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"纸币找零箱(5元)："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"纸币回收箱："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"硬币找零箱(1元)："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"硬币找零箱(5角)："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"硬币找零箱(1角)："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"硬币回收箱(1元)："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"硬币回收箱(5角)："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"硬币回收箱(1角)："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"硬币回收箱(老1角)："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"正常开机小时："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1),_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"备注："}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'value1',
                {
                  rules: [
                    { required: true, message: 'Please input your note!' } ],
                } ]),expression:"[\n                'value1',\n                {\n                  rules: [\n                    { required: true, message: 'Please input your note!' },\n                  ],\n                },\n              ]"}]})],1)],1)],1)],1),_c('div',{staticClass:"model-footer"},[_c('div',{staticClass:"left"},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"},on:{"click":function($event){_vm.show2 = false}}},[_vm._v(" 添加 ")]),_c('a-button',{staticClass:"marginr20",attrs:{"type":"primary","html-type":"submit"},on:{"click":function($event){_vm.show2 = false}}},[_vm._v(" 取消 ")])],1),_c('div',{staticClass:"right"},[_c('a-button',{attrs:{"type":"dashed","html-type":"submit"}},[_vm._v(" 上一条 ")]),_c('a-button',{staticClass:"marginr20",attrs:{"type":"dashed","html-type":"submit"}},[_vm._v(" 下一条 ")])],1)])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"left"},[_c('h3',{staticClass:"title"},[_vm._v("钞箱阈值管理列表")])])}]

export { render, staticRenderFns }