<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="desktop" />
				<span>监控功能</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 清机加钞管理 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form">
				<a-row :gutter="24">

					<a-col :span="4">
						<a-form-item :label="`所属银行：`">
							<a-tree-select style="width: 100%" placeholder="请选择银行" v-model="searchData.bank_id" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							 allow-clear :tree-data="bankTreeData">
								<span v-if="key == '0'" slot="title" slot-scope="{ key, value }">
									请选择银行类型
								</span>
							</a-tree-select>
						</a-form-item>
					</a-col>
					
					<a-col :span="4">
						<a-form-item :label="`网点名称：`">
							<a-select style="width: 120px" v-model="searchData.node_id" placeholder="请选择网点名称">
								<a-select-option v-for="item in outletsList" :value="item.node_id"> {{item.name}} </a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
				
					<a-col :span="4">
						<a-form-item :label="`维护类型：`">
							<a-select v-model="searchData.type"  style="width: 100%">
							      <a-select-option :value="0">
							        全部
							      </a-select-option>
							      <a-select-option :value="1">
							        加钞
							      </a-select-option>
							      <a-select-option :value="2">
							        清机
							      </a-select-option>
								  <a-select-option :value="3">
								    盘点
								  </a-select-option>
								  <a-select-option :value="4">
								    退钞
								  </a-select-option>
							    </a-select>
						</a-form-item>
					</a-col>
					<a-col :span="6">
						<a-form-item :label="`日期：`">
							<a-range-picker  :disabledDate="disabledDate" :ranges="{ '今天': [moment(), moment()] }" :key="pickerKey" @change="handleChangeDate" style="width: 120px" format="YYYY-MM-DD" />
						</a-form-item>
					</a-col>
					<a-col :span="6" :style="{
              textAlign: 'left',
              paddingLeft: '25px',
              marginTop: '6px',
            }">
						<a-button type="primary" @click="getList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">

				<div class="table-header-box">
					<div class="left">
						<h3 class="title">清机加钞列表</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="dow">
							导出Excel
						</a-button>
						<a-button type="primary" class="marginr20" @click="showDetail">
							详情
						</a-button>
					</div>
				</div>
				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" @change="handleTableChange"
				 :pagination="{total: total_page * 10}" :data-source="list" :scroll="{ x: 1200 }" rowKey="record_id" size="small">
					<span slot="date" slot-scope="date, info">
						{{info.province}} {{info.city}}
					</span>
				</a-table>
			</div>
		</div>


		<a-modal v-model="detailShow" width="800px"  title="详细信息" :footer="null">
			<a-spin :spinning="detailLoading">
				<a-table :columns="columns1" :key="editIndex" :pagination="{total: total_detail_page * 10}" :data-source="detailList" rowKey="id" size="small">
					
				</a-table>
				<div class="model-footer">
					<div class="left">
						<a-button type="primary" class="marginr20" html-type="submit" @click="detailShow = false">
							取消
						</a-button>
					</div>
					<div class="right">
						<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
							上一条
						</a-button>
						<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
							下一条
						</a-button>
					</div>
				</div>
			</a-spin>
		</a-modal>

	</div>
</template>

<script>
	import moment from 'moment';
	import http from '../http'

	const columns = [
		{
			title: "网点名称",
			dataIndex: "node_name",
			key: "225"
		},
		{
			title: "维护时间",
			dataIndex: "add_time",
			key: "28"
		},
		{
			title: "维护类型",
			dataIndex: "type_name",
			key: "27"
		},
		{
			title: "金额",
			dataIndex: "total_amount",
			key: "5"
		},
		{
			title: "维护人",
			dataIndex: "real_name",
			key: "5"
		},
		{
			title: "所属银行",
			dataIndex: "bank_name",
			key: "134"
		},
	];
	const columns1 = [
		{
			title: "类型",
			dataIndex: "type_name",
			key: "28"
		},
		{
			title: "面值",
			dataIndex: "dename",
			key: "218"
		},
		{
			title: "数量",
			dataIndex: "count",
			key: "228"
		},
		{
			title: "总金额",
			dataIndex: "amount",
			key: "238"
		}
	]

	export default {
		data() {
			return {
				expand: false,
				columns,
				columns1,

				detailShow: false,

				info: {
					name: ''
				},

				searchData: {
					page: 1,
					type: 0,
					start_date: '',
					end_date: '',
					bank_id: undefined,
					node_id: undefined,
				},
				list: [],
				total_page: 0,

				// 表格选择的数据, 存储的是id
				selectedRowKeys: [],
				// 存储的是数据
				selectedRowDataList: [],
				// 当前是第几条编辑信息
				editIndex: 0,

				detailLoading: false,
				
				detailList: [],
				total_detail_page: 0,
				
				
				bankTreeData: [],
				outletsList: [],
				pickerKey: Math.random(),
				
			};
		},
		mounted() {
			this.getList()
		},
		created () {
			this.getOutletsList()
			this.getBankTreeData()
		},
		methods: {
			moment,
			disabledDate(current) {
			     return current && current >= moment().subtract("days"); //当天之前的不可选，不包括当天
			   //return current && current < moment().endOf(‘day’);当天之前的不可选，包括当天
			},
			dow () {
				// window.open()
				http({
					method: 'post',
					url: '/api.transaction.export.record/',
					data: {
						bank_id: this.searchData.bank_id || 0,
						node_id: this.searchData.node_id || 0,
					}
				}).then((res) => {
					if (res.code == 200) {
						window.open(res.data)
					} else {
						this.$message.error(res.message);
					}
				})
			},
			handleReset() {
				this.pickerKey = Math.random()
				this.searchData = {
					page: 1,
					type: 0,
					start_date: '',
					end_date: '',
					bank_id: undefined,
					node_id: undefined,
				}
				this.getList();
			},

			handleSelectChange(value) {
				console.log(value);
				this.form.setFieldsValue({
					note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
				});
			},
			
			// 日期解析
			handleChangeDate(date) {
				this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
				this.searchData.end_date = this.moment(date[1]).format('YYYY-MM-DD')
			},
			
			// 认证通过 
			tocheck() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择角色');
					return
				}
				this.$confirm({
					title: '是否认证审核选中用户?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						let selectDatas = this.selectedRowKeys
						http({
							method: 'post',
							url: '/api.user.tocheck/',
							data: {
								user_id: selectDatas.join(),
								status: 1
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('用户认证审核成功');
								this.getList()
							} else {
								this.$message.error(res.data);
							}
						})
					}
				});
			},
			//删除
			deleteUser() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择用户');
					return
				}
				this.$confirm({
					title: '是否删除选中用户?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						let selectDatas = this.selectedRowKeys
						http({
							method: 'post',
							url: '/api.transaction.count/',
							data: {
								user_id: selectDatas.join(),
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('删除用户成功');
								this.getList()
							} else {
								this.$message.error(res.data);
							}
						})
					}
				});
			},
			// 表格分页
			handleTableChange(pagination, filters, sorter) {
				console.log(pagination, filters, sorter)
				this.searchData.page = pagination.current
				this.getList();
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				console.log('selectedRowKeys changed: ', selectedRowKeys);
				this.selectedRowKeys = selectedRowKeys;
			},
			// 列表
			getList() {
				http({
					method: 'post',
					url: '/api.transaction.calist/',
					data: {
						...this.searchData,
						bank_id: this.searchData.bank_id || 0,
						node_id: this.searchData.node_id || 0,
					}
				}).then((res) => {
					this.list = res.data.list
					this.total_page = res.data.total_page
				})
			},
			// 日期解析
			handleChangeDate(date) {
				this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
				this.searchData.end_date = this.moment(date[1]).format('YYYY-MM-DD')
			},

			showDetail() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择数据');
					return
				}
				console.log(this.selectedRowKeys)
				let selectDatas = []
				this.list.forEach((v) => {
					if (this.selectedRowKeys.includes(v.record_id)) {
						selectDatas.push(v)
					}
				})
				this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
				console.log(this.selectedRowDataList)
				this.editIndex = 0
				// 获取文章详情
				this.getDetail()
				this.detailShow = true
			},
			// 详情
			getDetail() {
				this.detailLoading = true
				this.info = this.selectedRowDataList[this.editIndex]
				http({
					method: 'post',
					url: '/api.transaction.cadetail/',
					data: {
						record_id: this.selectedRowDataList[this.editIndex].record_id,
						device_id: this.selectedRowDataList[this.editIndex].device_id,
						page: 1
					}
				}).then((res) => {
					this.detailList = res.data
					this.detailLoading = false
				})
			},
			// 点击上一条 或者 下一条、
			editToData(type) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				this.getDetail()
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},
			// 获取网点
			getOutletsList () {
				http({
					method: 'post',
					url: '/api.bank.node.list/',
					data: {
						page: 1,
						name: '',
						bank_id: 0
					}
				}).then((res) => {
					this.outletsList = res.data.list
				})
			},
			// 获取银行类型
			getBankTreeData() {
				http({
					method: 'post',
					url: '/api.bank.list/',
					data: {
						page: 1,
						parent_id: 0
					}
				}).then((res) => {
					this.bankTreeData = this.dataDealwith(res.data)
					console.log(this.bankTreeData)
				})
			},
			dataDealwith(treeData) {
				function setGrayNode(data) { //遍历树  获取id数组
					for (var i = 0; i < data.length; i++) {
						data[i] = {
							value: data[i].bank_id,
							key: data[i].bank_id,
							title: data[i].name,
							children: data[i].list || []
						} //子节点传给另一个函数专门用来改数据
						if (data[i].children && data[i].children.length) { // 如果当前节点有子节点，就递归调用一次
							setGrayNode(data[i].children);
						}
					}
				}
			
				setGrayNode(treeData)
				console.log(treeData)
				return treeData;
			},
		},
	};
</script>
<style>
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}

	.imgs {
		display: flex;
	}

	.imgs img {
		width: 140px;
		height: 200px;
	}
</style>
