<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="container" />
				<span>信息发布管理</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 文章列表 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form">
				<a-row :gutter="24">
					<a-col :span="6">
						<a-form-item :label="`文章标题：`">
							<a-input placeholder="请输入文章标题" v-model="searchData.title" />
						</a-form-item>
					</a-col>
					<a-col :span="7">
						<a-form-item :label="`选择日期：`">
							<a-range-picker :key="pickerKey" :disabledDate="disabledDate" :ranges="{ '今天': [moment(), moment()] }" @change="handleChangeDate" style="width: 120px" format="YYYY-MM-DD" />
						</a-form-item>
					</a-col>
					<a-col :span="6" :style="{
					  textAlign: 'left',
					  paddingLeft: '25px',
					  marginTop: '6px',
					}">
						<a-button type="primary" @click="getArticleList" class="">
							查询
						</a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">文章列表</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showAddEdit('add')">
							新增
						</a-button>
						<a-button type="primary" class="marginr20" @click="showAddEdit('edit')">
							编辑
						</a-button>
						<a-button type="primary" class="marginr20" @click="deleteArticle">
							删除
						</a-button>
					</div>
				</div>
				<a-table  :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" rowKey="art_id" :data-source="articleList" :scroll="{ x: 1300 }"
				 :show-total="(total) => `Total ${total} items`" size="small">
					<span slot="cate_id" slot-scope="cate_id">
						{{getCateName(cate_id)}}
					</span>
					<span slot="is_check" slot-scope="is_check, record">
						<a-switch checked-children="开" un-checked-children="关" :defaultChecked="is_check == 1"  @change="updateStatus(record)" />
					</span>
				</a-table>
			</div>
		</div>

		<a-modal v-model="addEditShow" width="800px" :title="isAdd ? '新增文章' : '编辑文章'" :footer="null" >
			<a-spin  :spinning="detailLoading">
			     
			
				<a-form-model ref="ruleForm" :model="info" :rules="rules" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }" style="margin: 0 auto; width: 100%">
					<a-row :gutter="24">
						<a-col :span="24">
							<a-form-model-item label="标题：" prop="title">
								<a-input v-model="info.title" />
							</a-form-model-item>
						</a-col>
						<a-col :span="24">
							<a-form-model-item label="文章分类：" prop="cate_id">
								<a-select v-model="info.cate_id" style="width: 120px">
									<a-select-option :value="item.cate_id" v-for="item in articleClassList" :key="item.cate_id">
										{{item.cate_name}}
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col :span="24">
							<a-form-model-item label="上传缩略图：" prop="image">
								<a-upload name="file" list-type="picture-card" class="avatar-uploader" :headers="headers" :show-upload-list="false" :action="actionUrl"
								 :before-upload="beforeUpload" @change="handleChange">
									<img v-if="imageUrl" :src="imageUrl" alt="avatar" />
									<div v-else>
										<a-icon :type="loading ? 'loading' : 'plus'" />
										<div class="ant-upload-text">
											Upload
										</div>
									</div>
								</a-upload>
							</a-form-model-item>
						</a-col>
						<a-col :span="24">
							<a-form-model-item label="内容：" prop="content">
								<div id="wangeditor">
									<div ref="editorElem" style="text-align:left;"></div>
								</div>
							</a-form-model-item>
						</a-col>
					</a-row>
				</a-form-model>
				<div class="model-footer">
				<div class="content-btns" v-if="isAdd">
					<a-button type="primary" class="marginr20" @click="addarticle()">
						保存
					</a-button>
					<a-button class="marginr20" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="left" v-if="!isAdd">
					<a-button type="primary" class="marginr20" @click="updateArticle">
						修改
					</a-button>
					<a-button class="marginr20"  @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="right" v-if="!isAdd" :key="editIndex">
					<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
						上一条
					</a-button>
					<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
						下一条
					</a-button>
				</div>
			</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import moment from "moment";
	const columns = [
		{
			title: "标题",
			dataIndex: "title",
			key: "32"
		},
		{
			title: "所属栏目",
			dataIndex: "cate_id",
			key: "33",
			scopedSlots: {
				customRender: 'cate_id'
			},
		},
		{
			title: "状态",
			dataIndex: "is_check",
			key: "34",
			scopedSlots: {
				customRender: 'is_check'
			},
		},
		{
			title: "发布时间",
			dataIndex: "add_time",
			key: "2"
		},
	];
	import http from '../http'
	import E from "wangeditor";
	export default {
		data() {
			return {
				columns,

				// 添加与编辑的数据
				info: {
					art_id: 0,
					cate_id: undefined,
					title: '',
					content: '',
					image: '',
				},
				rules: {
					title: [{
						required: true,
						message: '请输入文章标题',
						trigger: 'blur'
					}, ],
					cate_id: [{
						required: true,
						message: '请选择文章分类',
						trigger: 'change'
					}, ],
					image: [{
						validator: this.isUpdateImage
					}],
					content: [{
						validator: this.isWebText
					}],
				},
				addEditShow: false,
				isAdd: false,

				// 上传图片
				loading: false,
				imageUrl: '',

				editor: null,

				editIndex: 0,

				// 文章分类列表
				articleClassList: [],
				// 文章列表
				articleList: [],

				searchData: {
					page: 1,
					title: '',
					start_date: '',
					end_date: ''
				},
				dateText: '',
				
				selectedRowKeys: [],
				selectedRowDataList: [],
				
				detailLoading: false,
				headers: {
					token: localStorage.getItem("token")
				},
				actionUrl: window.WEBURL + '/api.article.upload/',
				
				pickerKey: Math.random()
			};
		},
		mounted() {
			this.getArticleList()
			this.getArticleClassList()
		},
		methods: {
			moment,
			disabledDate(current) {
			     return current && current >= moment().subtract("days"); //当天之前的不可选，不包括当天
			   //return current && current < moment().endOf(‘day’);当天之前的不可选，包括当天
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				console.log('selectedRowKeys changed: ', selectedRowKeys);
				this.selectedRowKeys = selectedRowKeys;
			},
			// 重置搜索
			handleReset() {
				this.pickerKey = Math.random()
				this.searchData = {
					page: 1,
					title: '',
					start_date: '',
					end_date: ''
				}
				this.getArticleList()
			},
			// 日期解析
			handleChangeDate(date) {
				this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
				this.searchData.end_date =  this.moment(date[1]).format('YYYY-MM-DD')
			},

			// 文章列表
			getArticleList() {
				console.log(this.dateText)
				http({
					method: 'post',
					url: '/api.article.list/',
					data: this.searchData
				}).then((res) => {
					this.articleList = res.data.list
					this.total_page = res.data.total_page
				})
			},
			// 显示 添加与编辑 的 弹窗
			showAddEdit(type) {
				if (type == 'add') {
					// 添加初始数据
					this.info = {
						art_id: 0,
						cate_id: undefined,
						title: '',
						content: '',
						image: '',
					}
					this.imageUrl = ''
					this.isAdd = true
				} else if (type == 'edit') {
					// 判断是否已经选择数据
					if (this.selectedRowKeys.length == 0) {
						this.$message.warning('请先选择文章');
						return
					}

					let selectDatas = []
					this.articleList.forEach((v) => {
						if (this.selectedRowKeys.includes(v.art_id)) {
							selectDatas.push(v)
						}
					})
					this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
					this.editIndex = 0
					// 获取文章详情
					this.getWebTextDetail()
					this.isAdd = false
				}

				this.addEditShow = true

				this.$nextTick(() => {
					this.setWebText()
				})
			},
			// 文章详情
			getWebTextDetail () {
				this.detailLoading = true
				http({
					method: 'post',
					url: '/api.article.detail/',
					data: {
						art_id: this.selectedRowDataList[this.editIndex].art_id
					}
				}).then((res) => {
					this.info = {
						art_id: res.data.art_id,
						cate_id: res.data.cate_id,
						title: res.data.title,
						content: res.data.content,
						image: '',
					}
					this.editor.txt.clear()
					this.imageUrl = res.data.image_thumb
					this.editor.txt.append(res.data.content)
					this.detailLoading = false
				})
			},
			// 添加文章 
			addarticle(type) {
				if (this.editor) {
					this.info.content = this.editor.txt.html()
				}
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						this.detailLoading = true
						http({
							method: 'post',
							url: '/api.article.add/',
							data: {
								...this.info,
								image: this.imageUrl
							}
						}).then((res) => {
							if (res.code == '200') {
								if (!type) {
									this.addEditShow = false
								}
								this.$message.success(type == 'edit' ? '修改文章信息成功' : '添加文章成功');
								this.getArticleList()
								this.detailLoading = false
							} else {
								this.$message.error(res.data);
								this.detailLoading = false
							}
						})
					} else {
						this.$message.warning('请检查是否已经输入必填项!');
						return false;
					}
				});
			},
			// 更新文章
			updateArticle() {
				this.addarticle('edit')
			},
			//
			deleteArticle() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择文章');
					return
				}
			
				this.$confirm({
					title: '是否删除选中文章?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk : () => {
						let selectDatas = []
						this.articleList.forEach((v) => {
							if(this.selectedRowKeys.includes(v.art_id)) {
								selectDatas.push(v.art_id)
							}
						})
						http({
							method: 'post',
							url: '/api.article.del/',
							data: {
								art_id: selectDatas.join(),
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('删除文章成功');
								this.getArticleList()
							} else {
								this.$message.error(res.data);
							}
						})
					}
				});
			},
			// 更新状态
			updateStatus (row) {
				http({
					method: 'post',
					url: '/api.article.update/',
					data: {
						art_id: row.art_id,
						status: row.is_check == 1 ? 0 : 1
					}
				}).then((res) => {
					if (res.code == '200') {
						this.$message.success('修改状态成功');
						this.getArticleList()
					} else {
						this.$message.error(res.data);
					}
				})
			},
			// 点击上一条 或者 下一条、
			editToData(type) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				this.getWebTextDetail()
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},

			// 上传图片
			handleChange(info) {
				if (info.file.status === 'uploading') {
					this.loading = true;
					return;
				}
				if (info.file.status === 'done') {
					console.log(info)
					this.imageUrl = info.file.response.data;
					this.loading = false;
				}
			},

			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					this.$message.error('You can only upload JPG file!');
				}
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('Image must smaller than 2MB!');
				}
				return isJpgOrPng && isLt2M;
			},
			// 设置富文本
			setWebText() {
				if (!this.editor) {
					this.editor = new E(this.$refs.editorElem);
					this.editor.config.height = 300
					// 编辑器的事件，每次改变会获取其html内容
					// this.editor.customConfig.onchange = html => {
					//   this.editorContent = html;
					//   this.catchData(this.editorContent); // 把这个html通过catchData的方法传入父组件
					// };
					this.editor.config.menus = [
						// 菜单配置
						'head', // 标题
						'bold', // 粗体
						'fontSize', // 字号
						'fontName', // 字体
						'italic', // 斜体
						'underline', // 下划线
						'strikeThrough', // 删除线
						'foreColor', // 文字颜色
						'backColor', // 背景颜色
						'link', // 插入链接
						'list', // 列表
						'justify', // 对齐方式
						'quote', // 引用
						'emoticon', // 表情
						'image', // 插入图片
						'table', // 表格
						'code', // 插入代码
						'undo', // 撤销
						'redo' // 重复
					];
					
					// 配置 server 接口地址
					this.editor.config.uploadImgHeaders = this.headers
					this.editor.config.uploadImgServer = this.actionUrl
					this.editor.config.uploadFileName = 'file'
					this.editor.config.uploadImgHooks = {
						// 图片上传并返回了结果，想要自己把图片插入到编辑器中
						// 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
						customInsert: function(insertImgFn, result) {
							// result 即服务端返回的接口
							console.log('customInsert', result)
					
							// insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
							insertImgFn(result.data)
						}
					}
					
					this.editor.create(); // 创建富文本实例
				}
				
				this.editor.txt.clear()
			},
			// img 判断
			isUpdateImage(rule, value, callback) {
				if (this.imageUrl == '') {
					callback('请上传缩略图')
				}
				// Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
				callback()
			},
			isWebText(rule, value, callback) {
				if (this.info.content == '') {
					callback('请输入文章内容')
				}
				// Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
				callback()
			},
			// 获取文章分类列表
			getArticleClassList() {
				http({
					method: 'post',
					url: '/api.cate.list/',
					data: {
						type: 3,
						parent_id: 0,
						page: 1
					}
				}).then((res) => {
					this.articleClassList = res.data.list
				})
			},
			getCateName(id) {
				let name = ''
				this.articleClassList.forEach((v) => {
					if (v.cate_id == id) {
						name = v.cate_name
						return
					}
				})
				return name
			}
		},
	};
</script>
<style>
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}

	.avatar-uploader>.ant-upload {
		width: 128px;
		height: 128px;
	}

	.ant-upload-select-picture-card i {
		font-size: 32px;
		color: #999;
	}

	.ant-upload-select-picture-card .ant-upload-text {
		margin-top: 8px;
		color: #666;
	}

	.avatar-uploader>.ant-upload img {
		width: 128px;
		height: 128px;
	}
</style>
