<template>
  <div class="home">
    <a-breadcrumb>
      <a-breadcrumb-item href="">
        <a-icon type="home" />
      </a-breadcrumb-item>
      <a-breadcrumb-item href="">
        <a-icon type="user" />
        <span>监控管理</span>
      </a-breadcrumb-item>
      <a-breadcrumb-item> 设备管理 </a-breadcrumb-item>
    </a-breadcrumb>

    <div class="search-list-box">
      <a-form
        class="ant-advanced-search-form"
        :form="form"
        @submit="handleSearch"
      >
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item :label="`信息标题：`">
              <a-input placeholder="请输入设备编号" />
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item :label="`信息分类：`">
              <a-select
                style="width: 120px"
                @change="handleChange"
                placeholder="请选择"
              >
                <a-select-option value="jack"> Jack </a-select-option>
                <a-select-option value="lucy"> Lucy </a-select-option>
                <a-select-option value="disabled" disabled>
                  Disabled
                </a-select-option>
                <a-select-option value="Yiminghe"> yiminghe </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item :label="`选择日期：`">
              <a-range-picker
                :show-time="{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment('00:00:00', 'HH:mm:ss'),
                    moment('11:59:59', 'HH:mm:ss'),
                  ],
                }"
                style="width: 120px"
                format="YYYY-MM-DD"
              />
            </a-form-item>
          </a-col>
          <a-col
            :span="6"
            :style="{
              textAlign: 'left',
              paddingLeft: '25px',
              marginTop: '6px',
            }"
          >
            <a-button type="primary" html-type="submit" class="">
              查询
            </a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <div class="search-result-list">
        <div class="table-header-box">
          <div class="left">
            <h3 class="title">帮助信息列表</h3>
          </div>
          <div class="right">
            <a-button
              type="primary"
              class="marginr20"
              html-type="submit"
            >
              编辑
            </a-button>
            <a-button type="primary" class="marginr20" html-type="submit">
              删除
            </a-button>
          </div>
        </div>
        <a-table
          :row-selection="rowSelection"
          :columns="columns"
          :data-source="data"
          :scroll="{ x: 1300, y: 300 }"
          :show-total="(total) => `Total ${total} items`"
          size="small"
        >
          <span slot="age" slot-scope="age">
            <a-input-number  v-model="age" @change="onChange" />
          </span>
        </a-table>
      </div>
    </div>

    <a-modal
      v-model="show1"
      width="800px"
      title="硬币供求详细信息"
      :footer="null"
      @ok="handleOk"
    >
      <a-form
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        @submit="handleSubmit"
        style="margin: 0 auto; width: 80%"
      >
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="信息类型：">
              <a-input
                v-decorator="[
                  'value1',
                  {
                    rules: [
                      { required: true, message: 'Please input your note!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="1元硬币数量：">
              <a-input
                v-decorator="[
                  'value2',
                  {
                    rules: [
                      { required: true, message: 'Please input your note!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="5角硬币数量：">
              <a-input
                v-decorator="[
                  'value3',
                  {
                    rules: [
                      { required: true, message: 'Please input your note!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="1角硬币数量：">
              <a-input
                v-decorator="[
                  'value5',
                  {
                    rules: [
                      { required: true, message: 'Please input your note!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="用户名称:">
              <a-input
                v-decorator="[
                  'value4',
                  {
                    rules: [
                      { required: true, message: 'Please input your note!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="用户类型：">
              <a-input
                v-decorator="[
                  'value5',
                  {
                    rules: [
                      { required: true, message: 'Please input your note!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="联系电话：">
              <a-input
                v-decorator="[
                  'value6',
                  {
                    rules: [
                      { required: true, message: 'Please input your note!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="审核状态：">
              <a-input
                v-decorator="[
                  'value6',
                  {
                    rules: [
                      { required: true, message: 'Please input your note!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="发布时间：">
              <a-input
                v-decorator="[
                  'value6',
                  {
                    rules: [
                      { required: true, message: 'Please input your note!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="用户地址：">
              <a-input
                v-decorator="[
                  'value6',
                  {
                    rules: [
                      { required: true, message: 'Please input your note!' },
                    ],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
      <div class="model-footer">
        <div class="left">
          <a-button
            type="primary"
            class="marginr20"
            html-type="submit"
            @click="show1 = false"
          >
            取消
          </a-button>
        </div>
        <div class="right">
          <a-button type="dashed" class="" html-type="submit">
            上一条
          </a-button>
          <a-button type="dashed" class="marginr20" html-type="submit">
            下一条
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const columns1 = [
  {
    title: "信息类型",
    dataIndex: "address",
    key: "1",
    sorter: (a, b) => a.age - b.age,
  },
  {
    title: "面额(元)",
    dataIndex: "address",
    key: "2",
    sorter: (a, b) => a.age - b.age,
  },
  {
    title: "剩余数量",
    dataIndex: "address",
    key: "3",
    sorter: (a, b) => a.age - b.age,
  },
  {
    title: "总金额(元)",
    dataIndex: "address",
    key: "4",
    sorter: (a, b) => a.age - b.age,
  },
];
const columns = [
  { title: "ID", dataIndex: "address", key: "31" },
  { title: "标题", dataIndex: "address", key: "32" },
  { title: "所属栏目", dataIndex: "address", key: "33" },
  { title: "推荐", dataIndex: "address", key: "34" },
  { title: "浏览量", dataIndex: "address", key: "1" },
  { title: "更新时间", dataIndex: "address", key: "2" },
  { title: "排序", dataIndex: "age", key: "22", scopedSlots: { customRender: 'age' }, },
];
const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "2",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "3",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "4",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "5",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "6",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "7",
    name: "Jim Green",
    age: 40,
    address: "London Park",
  },
];
export default {
  data() {
    return {
      expand: false,
      form: this.$form.createForm(this, { name: "advanced_search" }),
      data,
      columns,
      columns1,
      rowSelection,

      show1: false,
    };
  },
  computed: {
    count() {
      return this.expand ? 11 : 7;
    },
  },
  updated() {
    console.log("updated");
  },
  methods: {
    moment,
    handleSearch(e) {
      e.preventDefault();
      this.form.validateFields((error, values) => {
        console.log("error", error);
        console.log("Received values of form: ", values);
      });
    },

    handleReset() {
      this.form.resetFields();
    },

    toggle() {
      this.expand = !this.expand;
    },

    handleChange() {},

    handleSelectChange(value) {
      console.log(value);
      this.form.setFieldsValue({
        note: `Hi, ${value === "male" ? "man" : "lady"}!`,
      });
    },
  },
};
</script>
<style>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.search-list-box .ant-form {
  max-width: none;
}
.search-list-box .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>
