<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="setting" />
				<span>系统管理</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 轮播图管理 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form">
				<a-row :gutter="24">
					<a-col :span="8">
						<a-form-item :label="`选择日期：`">
							<a-range-picker :disabledDate="disabledDate" :ranges="{ '今天': [moment(), moment()] }" :key="pickerKey" @change="handleChangeDate" style="width: 120px" format="YYYY-MM-DD" />
						</a-form-item>
					</a-col>
					<a-col :span="6" :style="{
					  textAlign: 'left',
					  paddingLeft: '25px',
					  marginTop: '6px',
					}">
						<a-button type="primary" @click="getArticleList" class="">
							查询
						</a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">轮播图列表</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showAddEdit('add')">
							新增
						</a-button>
						<a-button type="primary" class="marginr20" @click="showAddEdit('edit')">
							编辑
						</a-button>
						<a-button type="primary" class="marginr20" @click="deleteArticle">
							删除
						</a-button>
					</div>
				</div>
				<a-table  :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" rowKey="id" :data-source="articleList" :scroll="{ x: 1300 }"
				 :show-total="(total) => `Total ${total} items`" size="small">
					<span slot="image" slot-scope="image">
						<img :src="image" style="height:100px"/>
					</span>
					<span slot="is_check" slot-scope="is_check">
						<a-tag color="red" v-if="is_check == 0">
							不显示
						</a-tag>
						<a-tag color="blue" v-if="is_check == 1">
显示	
						</a-tag>
					</span>
				</a-table>
			</div>
		</div>

		<a-modal v-model="addEditShow" width="500px" :title="isAdd ? '新增轮播图' : '编辑轮播图'" :footer="null" >
			<a-spin  :spinning="detailLoading">
			     
			
				<a-form-model ref="ruleForm" :model="info" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" style="margin: 0 auto; width: 100%">
					<a-row :gutter="24">
						<a-col :span="24">
							<a-form-model-item label="标题：" prop="ads_name">
								<a-input v-model="info.ads_name" placeholder="请输入标题"/>
							</a-form-model-item>
						</a-col>
						<a-col :span="24">
							<a-form-model-item label="上传缩略图：" prop="image">
								<a-upload name="file" list-type="picture-card" class="avatar-uploader" :headers="headers" :show-upload-list="false" :action="actionUrl"
								 :before-upload="beforeUpload" @change="handleChange">
									<img v-if="imageUrl" :src="imageUrl" alt="avatar" />
									<div v-else>
										<a-icon :type="loading ? 'loading' : 'plus'" />
										<div class="ant-upload-text">
											Upload
										</div>
									</div>
								</a-upload>
							</a-form-model-item>
						</a-col>
						<a-col :span="24">
							<a-form-model-item label="状态：">
								<a-radio-group name="radioGroup" v-model="info.is_check">
								    <a-radio :value="0">
								      不显示
								    </a-radio>
								    <a-radio :value="1">
								      显示
								    </a-radio>
								  </a-radio-group>
							</a-form-model-item>
						</a-col>
					</a-row>
				</a-form-model>
				<div class="model-footer">
				<div class="content-btns" v-if="isAdd">
					<a-button type="primary" class="marginr20" @click="addarticle()">
						保存
					</a-button>
					<a-button class="marginr20" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="left" v-if="!isAdd">
					<a-button type="primary" class="marginr20" @click="updateArticle">
						修改
					</a-button>
					<a-button class="marginr20"  @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="right" v-if="!isAdd" :key="editIndex">
					<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
						上一条
					</a-button>
					<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
						下一条
					</a-button>
				</div>
			</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import moment from "moment";
	const columns = [{
			title: "ID",
			dataIndex: "id",
			key: "31"
		},
		{
			title: "标题",
			dataIndex: "ads_name",
			key: "32"
		},
		{
			title: "图片",
			dataIndex: "image",
			key: "33",
			scopedSlots: {
				customRender: 'image'
			},
			width: 600
		},
		{
			title: "状态",
			dataIndex: "is_check",
			key: "34",
			scopedSlots: {
				customRender: 'is_check'
			},
		},
		{
			title: "发布时间",
			dataIndex: "add_time",
			key: "2"
		},
	];
	import http from '../http'
	import E from "wangeditor";
	export default {
		data() {
			return {
				columns,

				// 添加与编辑的数据
				info: {
					id: 0,
					is_check: 0,
					url: '',
					ads_name: '',
					image: '',
				},
				rules: {
					ads_name: [{
						required: true,
						message: '请输入轮播图标题',
						trigger: 'blur'
					}, ],
					image: [{
						validator: this.isUpdateImage
					}],
				},
				addEditShow: false,
				isAdd: false,

				// 上传图片
				loading: false,
				imageUrl: '',

				editor: null,

				editIndex: 0,

				// 轮播图分类列表
				articleClassList: [],
				// 轮播图列表
				articleList: [],

				searchData: {
					page: 1,
					start_date: '',
					end_date: ''
				},
				dateText: '',
				
				selectedRowKeys: [],
				selectedRowDataList: [],
				
				detailLoading: false,
				
				headers: {
					token: localStorage.getItem("token")
				},
				actionUrl: window.WEBURL + '/api.article.upload/',
				
				pickerKey: Math.random()
			};
		},
		mounted() {
			this.getArticleList()
			this.getArticleClassList()
		},
		methods: {
			moment,
			disabledDate(current) {
			     return current && current >= moment().subtract("days"); //当天之前的不可选，不包括当天
			   //return current && current < moment().endOf(‘day’);当天之前的不可选，包括当天
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				console.log('selectedRowKeys changed: ', selectedRowKeys);
				this.selectedRowKeys = selectedRowKeys;
			},
			// 重置搜索
			handleReset() {
				this.pickerKey = Math.random()
				this.searchData = {
					page: 1,
					start_date: '',
					end_date: ''
				}
				this.getArticleList()
			},
			// 日期解析
			handleChangeDate(date) {
				this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
				this.searchData.end_date =  this.moment(date[1]).format('YYYY-MM-DD')
			},

			// 轮播图列表
			getArticleList() {
				console.log(this.dateText)
				http({
					method: 'post',
					url: '/api.ads.list/',
					data: this.searchData
				}).then((res) => {
					this.articleList = res.data.list
					this.total_page = res.data.total_page
				})
			},
			// 显示 添加与编辑 的 弹窗
			showAddEdit(type) {
				if (type == 'add') {
					// 添加初始数据
					this.info = {
						id: 0,
						is_check: 0,
						url: '',
						ads_name: '',
						image: '',
					}
					this.imageUrl = ''
					this.isAdd = true
				} else if (type == 'edit') {
					// 判断是否已经选择数据
					if (this.selectedRowKeys.length == 0) {
						this.$message.warning('请先选择轮播图');
						return
					}

					let selectDatas = []
					this.articleList.forEach((v) => {
						if (this.selectedRowKeys.includes(v.id)) {
							selectDatas.push(v)
						}
					})
					this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
					this.editIndex = 0
					// 获取轮播图详情
					this.getWebTextDetail()
					this.isAdd = false
				}

				this.addEditShow = true

			},
			// 轮播图详情
			getWebTextDetail () {
				this.detailLoading = true
				this.info = this.selectedRowDataList[this.editIndex]
				this.imageUrl = this.selectedRowDataList[this.editIndex].image
				this.detailLoading = false
			},
			// 添加轮播图 
			addarticle(type) {
				if (this.editor) {
					this.info.content = this.editor.txt.html()
				}
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						this.detailLoading = true
						http({
							method: 'post',
							url: '/api.ads.add/',
							data: {
								...this.info,
								image: this.imageUrl,
								url: this.imageUrl,
								id: this.info.id || 0
							}
						}).then((res) => {
							if (res.code == '200') {
								if (!type) {
									this.addEditShow = false
								}
								this.$message.success(type == 'edit' ? '修改轮播图信息成功' : '添加轮播图成功');
								this.getArticleList()
								this.detailLoading = false
							} else {
								this.$message.error(res.data);
								this.detailLoading = false
							}
						})
					} else {
						this.$message.warning('请检查是否已经输入必填项!');
						return false;
					}
				});
			},
			// 更新轮播图
			updateArticle() {
				this.addarticle('edit')
			},
			//
			deleteArticle() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择轮播图');
					return
				}
			
				this.$confirm({
					title: '是否删除选中轮播图?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk : () => {
						let selectDatas = []
						this.articleList.forEach((v) => {
							if(this.selectedRowKeys.includes(v.id)) {
								selectDatas.push(v.id)
							}
						})
						http({
							method: 'post',
							url: '/api.ads.del/',
							data: {
								id: selectDatas.join(),
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('删除轮播图成功');
								this.getArticleList()
							} else {
								this.$message.error(res.data);
							}
						})
					}
				});
			},
			
			// 点击上一条 或者 下一条、
			editToData(type) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				this.getWebTextDetail()
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},

			// 上传图片
			handleChange(info) {
				if (info.file.status === 'uploading') {
					this.loading = true;
					return;
				}
				if (info.file.status === 'done') {
					console.log(info)
					this.imageUrl = info.file.response.data;
					this.loading = false;
				}
			},

			beforeUpload(file) {
				const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
				if (!isJpgOrPng) {
					this.$message.error('You can only upload JPG file!');
				}
				const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isLt2M) {
					this.$message.error('Image must smaller than 2MB!');
				}
				return isJpgOrPng && isLt2M;
			},
			// img 判断
			isUpdateImage(rule, value, callback) {
				if (this.imageUrl == '') {
					callback('请上传缩略图')
				}
				// Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
				callback()
			},
			isWebText(rule, value, callback) {
				if (this.info.content == '') {
					callback('请输入轮播图内容')
				}
				// Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
				callback()
			},
			// 获取轮播图分类列表
			getArticleClassList() {
				http({
					method: 'post',
					url: '/api.cate.list/',
					data: {
						type: 3,
						parent_id: 0,
						page: 1
					}
				}).then((res) => {
					this.articleClassList = res.data.list
				})
			},
			getCateName(id) {
				let name = ''
				this.articleClassList.forEach((v) => {
					if (v.id == id) {
						name = v.cate_name
						return
					}
				})
				return name
			}
		},
	};
</script>
<style>
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}

	.avatar-uploader>.ant-upload {
		width: 128px;
		height: 128px;
	}

	.ant-upload-select-picture-card i {
		font-size: 32px;
		color: #999;
	}

	.ant-upload-select-picture-card .ant-upload-text {
		margin-top: 8px;
		color: #666;
	}

	.avatar-uploader>.ant-upload img {
		width: 128px;
		height: 128px;
	}
</style>
