<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="setting" />
				<span>系统管理</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 银行网点 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form" :form="form" @submit="handleSearch">
				<a-row :gutter="24">
					<a-col :span="7">
						<a-form-item :label="`银行：`">
							<a-tree-select style="width: 100%" placeholder="请选择银行" v-model="searchData.bank_id" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							 allow-clear :tree-data="bankTreeData">
								<span v-if="key == '0'" slot="title" slot-scope="{ key, value }">
									请选择银行类型
								</span>
							</a-tree-select>
						</a-form-item>
					</a-col>
					<a-col :span="7">
						<a-form-item :label="`网点名称：`">
							<a-input placeholder="网点名称" v-model="searchData.name" />
						</a-form-item>
					</a-col>
					<a-col :span="7" :style="{
					  textAlign: 'left',
					  paddingLeft: '45px',
					  marginTop: '6px',
					}">
						<a-button type="primary" html-type="submit" @click="getList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">银行网点列表</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showAddEdit('add')">
							新增
						</a-button>
						<a-button type="primary" class="marginr20" @click="showAddEdit('edit')">
							详情
						</a-button>
						<a-button type="primary" class="marginr20" @click="deleteData">
							删除
						</a-button>

					</div>
				</div>

				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="{total: total_page * 10}" @change="pageChange" :columns="columns" 
				 :data-source="roleList" rowKey="node_id" size="small">
					<span slot="province" slot-scope="province, data">
						{{data.province}} {{data.city}} {{data.district}} {{data.address}}
					</span>
				</a-table>
			</div>
		</div>


		<a-modal v-model="addEditShow" :title="isAdd ? '新增银行网点' : '编辑银行网点'" :footer="null">
			<a-form-model ref="ruleForm" :key="editIndex" :model="info" :rules="rules" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" style="margin: 0 auto; width: 80%">
				<a-row :gutter="24">
					<a-col :span="24">
						<a-form-model-item label="网点名称：" prop="name">
							<a-input v-model="info.name"  placeholder="请输入银行网点名称"/>
						</a-form-model-item>
					</a-col>

					<a-col :span="24">
						<a-form-model-item label="银行：" prop="name">
							<a-tree-select style="width: 100%" placeholder="请选择银行" v-model="info.bank_id" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							 allow-clear :tree-data="bankTreeData">
								<span v-if="key == '0'" slot="title" slot-scope="{ key, value }">
								</span>
							</a-tree-select>
						</a-form-model-item>
						
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="联系人：" prop="real_name">
							<a-input v-model="info.real_name"  placeholder="请输入联系人"/>
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="联系方式：" prop="contact">
							<a-input v-model="info.contact"  placeholder="请输入联系方式"/>
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="请选择类型：" prop="type">
							<a-select style="width: 100%"  placeholder="请选择类型" v-model="info.type">
								<a-select-option :value="1">
									商业银行
								</a-select-option>
								<a-select-option :value="2">
									其他
								</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="营业时间：" prop="open_hours">
							<a-input v-model="info.open_hours"  placeholder="请输入营业时间" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="城市：" prop="province">
							<area-select @change="selectArea"  :default-value="info.province ? [info.province , info.city, info.district] : []" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="详细地址：" prop="address">
							<a-input v-model="info.address"  placeholder="请输入详细地址" />
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
			<div class="model-footer">
				<div class="content-btns" v-if="isAdd">
					<a-button type="primary" class="marginr20" html-type="submit" @click="add()">
						保存
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="left" v-if="!isAdd">
					<a-button type="primary" class="marginr20" html-type="submit" @click="updateRole">
						修改
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="right" v-if="!isAdd" :key="editIndex">
					<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
						上一条
					</a-button>
					<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
						下一条
					</a-button>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	const columns = [{
			title: "ID",
			dataIndex: "node_id",
		}, {
			title: "网点名称",
			dataIndex: "name",
		},
		{
			title: "银行名称",
			dataIndex: "bank_name",
		},
		{
			title: "联系人",
			dataIndex: "real_name",
		},
		{
			title: "联系方式",
			dataIndex: "contact",
		},
		{
			title: "详细地址",
			scopedSlots: {
				customRender: 'province'
			},
			dataIndex: "address",
		},
	];
	import AreaSelect from "@/components/City/index.vue";
	import http from '../http'
	export default {
		data() {
			return {
				expand: false,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				show1: false,
				show4: false,
				// 编辑与添加
				addEditShow: false,
				// 是否添加状态
				isAdd: false,
				// 搜索
				searchData: {
					name: '',
					bank_id: undefined,
					page: 1
				},
				// 银行网点列表
				roleList: [],
				// 多少条数据 * 10
				total_page: 0,

				// 添加与编辑的数据
				info: {
					node_id: 0,
					name: '',
					bank_id: undefined,
					real_name: '',
					contact: '',
					type: undefined,
					open_hours: '',
					province: '',
					city: '',
					district: '',
					address: ''
				},
				rules: {
					province: [{
						required: true,
						message: '请选择城市',
						trigger: 'change'
					}, ],
					name: [{
						required: true,
						message: '请输入银行网点名称',
						trigger: 'blur'
					}, ],
					real_name: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}, ],
					contact: [{
						required: true,
						message: '请输入联系方式',
						trigger: 'blur'
					}, ],
					open_hours: [{
						required: true,
						message: '请输入营业时间',
						trigger: 'blur'
					}, ],
					province: [{
						required: true,
						message: '请选择城市',
						trigger: 'change'
					}, ],
					address: [{
						required: true,
						message: '请输入详细地址',
						trigger: 'blur'
					}, ],
					bank_id: [{
						required: true,
						message: '请选择银行',
						trigger: 'change'
					}, ],
					type: [{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}, ],
				},
				// 表格选择的数据, 存储的是id
				selectedRowKeys: [],
				// 存储的是数据
				selectedRowDataList: [],
				// 当前是第几条编辑信息
				editIndex: 0,

				bankTreeData: [],
			};
		},
		components: {
			AreaSelect
		},
		computed: {
			count() {
				return this.expand ? 11 : 7;
			},
		},
		updated() {
			console.log("updated");
		},
		created() {
			this.getBankTreeData()
		},
		mounted() {
			this.getList()
		},
		methods: {
			pageChange (e) {
				this.searchData.page = e.current
				this.getList()
			},
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {
					console.log("error", error);
					console.log("Received values of form: ", values);
				});
			},

			handleReset() {
				this.searchData = {
					name: '',
					bank_id: undefined,
					page: 1
				}
				this.getList()
			},

			toggle() {
				this.expand = !this.expand;
			},

			handleChange() {},

			handleSelectChange(value) {
				console.log(value);
				this.form.setFieldsValue({
					note: `Hi, ${value === "male" ? "man" : "lady"}!`,
				});
			},
			// 点击上一条 或者 下一条、
			editToData(type) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				this.info = this.selectedRowDataList[this.editIndex]
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},
			//选择地区之后的回调
			selectArea(selectedArea) {
				this.info.province = selectedArea[0]
				this.info.city = selectedArea[1]
				this.info.district = selectedArea[2]
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				console.log('selectedRowKeys changed: ', selectedRowKeys);
				this.selectedRowKeys = selectedRowKeys;
			},
			// 显示 添加与编辑 的 弹窗
			showAddEdit(type) {
				if (type == 'add') {
					// 添加初始数据
					this.info = {
						node_id: 0,
						name: '',
						bank_id: undefined,
						real_name: '',
						contact: '',
						type: undefined,
						open_hours: '',
						province: '',
						city: '',
						district: '',
						address: ''
					}
					this.isAdd = true
				} else if (type == 'edit') {
					// 判断是否已经选择数据
					if (this.selectedRowKeys.length == 0) {
						this.$message.warning('请先选择银行网点');
						return
					}

					let selectDatas = []
					this.roleList.forEach((v) => {
						if (this.selectedRowKeys.includes(v.node_id)) {
							selectDatas.push(v)
						}
					})
					this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
					this.editIndex = 0
					this.getDetail()
					this.isAdd = false
				}

				this.addEditShow = true
			},
			getDetail() {
				this.detailLoading = true
				http({
					method: 'post',
					url: '/api.bank.node.detail/',
					data: {
						node_id: this.selectedRowDataList[this.editIndex].node_id
					}
				}).then((res) => {
					this.info = res.data
					this.detailLoading = false
				})
			},
			// 搜索
			search() {
				this.searchData.page = 1
				this.getList()
			},
			// 银行网点列表
			getList() {
				http({
					method: 'post',
					url: '/api.bank.node.list/',
					data: { ...this.searchData,
						bank_id: this.searchData.bank_id || 0
					}
				}).then((res) => {
					this.roleList = res.data.list
					this.total_page = res.data.total_page
				})
			},

			// 
			deleteData() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择银行网点');
					return
				}

				this.$confirm({
					title: '是否删除选中银行网点?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						let selectDatas = []
						this.roleList.forEach((v) => {
							if (this.selectedRowKeys.includes(v.node_id)) {
								selectDatas.push(v.node_id)
							}
						})
						http({
							method: 'post',
							url: '/api.bank.node.del/',
							data: {
								node_id: selectDatas.join(),
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('删除银行网点成功');
								this.getList()
							} else {
								this.$message.error(res.data);
							}
						})
					}
				});


			},
			// 添加银行网点
			add(type) {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						http({
							method: 'post',
							url: '/api.bank.node.add/',
							data: { ...this.info,
								type: 2,
								parent_id: 0
							}
						}).then((res) => {
							if (res.code == '200') {
								if (!type) {
									this.addEditShow = false
								}
								this.$message.success(type == 'edit' ? '修改银行网点信息成功' : '添加银行网点成功');
								this.getList()
							} else {
								this.$message.error(res.data);
							}
						})
					} else {
						this.$message.warning('请检查是否已经输入必填项!');
						return false;
					}
				});
			},
			// 更新银行网点
			updateRole() {
				this.add('edit')
			},
			// 获取银行类型
			getBankTreeData() {
				http({
					method: 'post',
					url: '/api.bank.list/',
					data: {
						page: 1,
						parent_id: 0
					}
				}).then((res) => {
					this.bankTreeData = this.dataDealwith(res.data)
					console.log(this.bankTreeData)
				})
			},
			dataDealwith(treeData) {
				console.log(treeData)

				function setGrayNode(data) { //遍历树  获取id数组
					for (var i = 0; i < data.length; i++) {
						data[i] = {
							value: data[i].bank_id,
							key: data[i].bank_id,
							title: data[i].name,
							children: data[i].list || []
						} //子节点传给另一个函数专门用来改数据
						if (data[i].children && data[i].children.length) { // 如果当前节点有子节点，就递归调用一次
							setGrayNode(data[i].children);
						}
					}
				}

				setGrayNode(treeData)
				console.log(treeData)
				return treeData;
			},
		},
	};
</script>
<style lang="less">
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}

	.tes {
		width: 90%;
		margin: 0 auto;
		border: 1px solid #e8e8e8;
		border-radius: 3px;

		.table-th {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #e8e8e8;
			height: 30px;

			div {
				width: 33.33%;
				box-sizing: border-box;
				height: 30px;
				padding-left: 20px;
				font-weight: 700;
				border-right: 1px solid #e8e8e8;
				display: flex;
				align-items: center;

				&:last-child {
					border-right: none;
				}
			}
		}

		.taes {
			display: flex;
			align-items: center;

			&>div {
				width: 33.33%;
			}

			.chebox {
				overflow: hidden;
				box-sizing: border-box;
				height: 50px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e8e8e8;

				&:last-child {
					border-bottom: none;
				}

				.chebox-item {
					box-sizing: border-box;
					padding-left: 20px;
				}
			}

			.left {
				height: 300px;
				border-right: 1px solid #e8e8e8;

				.chebox {
					height: 100%;
				}
			}

			.contet {
				height: 300px;
				border-right: 1px solid #e8e8e8;
			}

			.right {
				height: 300px;
			}
		}
	}
</style>
