<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="setting" />
				<span>系统管理</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 管理员管理 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form" :form="form" @submit="handleSearch">
				<a-row :gutter="24">
					<a-col :span="7">
						<a-form-item :label="`所属部门：`">
							<a-select style="width: 100%" placeholder="请选择所属部门" v-model="searchData.dept_id">
								<a-select-option :value="0"> 全部 </a-select-option>
								<a-select-option v-for="item in depList" :value="item.cate_id">
									{{item.cate_name}}
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
					<a-col :span="7">
						<a-form-item :label="`用户帐号：`">
							<a-input placeholder="请输入用户帐号" v-model="searchData.admin_name" />
						</a-form-item>
					</a-col>
					<a-col :span="7">
						<a-form-item :label="`用户姓名：`">
							<a-input placeholder="请输入用户姓名" v-model="searchData.real_name" />
						</a-form-item>
					</a-col>
					<a-col :span="7">
						<a-form-item :label="`帐号状态：`">
							<a-select style="width: 120px" placeholder="请选择帐号状态" v-model="searchData.is_lock">
								<a-select-option :value="0"> 全部 </a-select-option>
								<a-select-option :value="2"> 锁定 </a-select-option>
								<a-select-option :value="1">
									正常</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>

					<a-col :span="7" :style="{
					  textAlign: 'left',
					  paddingLeft: '45px',
					  marginTop: '6px',
					}">
						<a-button type="primary" html-type="submit" @click="getUserList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">管理员列表</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showAddEdit('add')">
							新增
						</a-button>
						<a-button type="primary" class="marginr20" @click="showAddEdit('edit')">
							详情
						</a-button>
						<a-button type="primary" class="marginr20" @click="lockUser">
							锁定
						</a-button>
						<a-button type="primary" class="marginr20" @click="passwordUpdate">
							重置密码
						</a-button>
						<a-button type="primary" class="marginr20" @click="deleteUser">
							批量删除
						</a-button>
					</div>
				</div>
				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" :total="total_page * 10"
				 :data-source="userList" rowKey="admin_id" :scroll="{ x: 1000, }" :show-total="(total) => `Total ${total} items`"
				 size="small">
					<span slot="bank_type" slot-scope="bank_type, data">
						{{getBankName(bank_type, data.type_id)}}
					</span>
					<span slot="province" slot-scope="province, data">
						<span v-if="province">{{province}} {{data.city}} {{data.district}}</span>
						<span v-else>全部</span>
					</span>
					<span slot="is_lock" slot-scope="is_lock">
						<a-tag color="red" v-if="is_lock == 1">
							锁定
						</a-tag>
						<a-tag color="blue" v-if="is_lock == 2 || is_lock == 0">
							正常
						</a-tag>
					</span>
				</a-table>
			</div>
		</div>

		<a-modal v-model="addEditShow" title="新增用户" width="800px" :footer="null">
			<a-spin :spinning="detailLoading">
				<a-form-model ref="ruleForm" :model="info" :rules="rules" :label-col="{ span: 7 }" :wrapper-col="{ span: 12 }"
				 style="margin: 0 auto; width: 80%">
					<a-row :gutter="24">
						<a-col :span="12">
							<a-form-model-item label="用户帐号："  prop="admin_name">
								<a-input v-model="info.admin_name" :disabled="info.admin_id" placeholder="请输入用户帐号" />
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							
							<a-form-model-item label="所属部门：" prop="dept_id">
								<a-select style="width: 100%" placeholder="请选择所属部门" v-model="info.dept_id">
									<a-select-option v-for="item in depList" :value="item.cate_id">
										{{item.cate_name}}
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="角色：" prop="role_id">
								<a-select style="width: 100%" placeholder="请选择角色" v-model="info.role_id">
									<a-select-option v-for="item in roleList" :value="item.role_id">
										{{item.role_name}}
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						
						<a-col :span="12">
							<a-form-model-item label="用户姓名：" prop="real_name">
								<a-input v-model="info.real_name" placeholder="请输入用户姓名" />
							</a-form-model-item>
						</a-col>

						<a-col :span="12">
							<a-form-model-item label="单位电话：" prop="contact">
								<a-input v-model="info.contact" placeholder="请输入单位电话" />
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="移动电话：" prop="mobile">
								<a-input v-model="info.mobile" placeholder="请输入移动电话" />
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="性别：">
								<a-radio-group v-model="info.gender">
									<a-radio :value="1">
										男
									</a-radio>
									<a-radio :value="2">
										女
									</a-radio>
								</a-radio-group>
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="机构类型：" prop="type_id">
								<a-select style="width: 100%" placeholder="请选择机构类型" v-model="info.type_id">
									<a-select-option :value="1">
										服务商
									</a-select-option>
									<a-select-option :value="2">
										监管机构
									</a-select-option>
									<a-select-option :value="3">
										银行
									</a-select-option>
								</a-select>
							</a-form-model-item>
						</a-col>
						<a-col :span="12">
							<a-form-model-item label="银行类型：" v-if="info.type_id == 3">
								<a-tree-select style="width: 100%" placeholder="请选择银行类型" v-model="info.bank_type"
								 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" allow-clear :tree-data="bankTreeData">
									<span v-if="key == '0'" slot="title" slot-scope="{ key, value }">
										请选择银行类型
									</span>
								</a-tree-select>
							 </a-form-model-item> 
							 </a-col> 
							 <a-col :span="24">
								<a-row>
									<a-col :span="12">
										<a-form-model-item label="区域：">
											<div style="display: flex;width:600px">
												<div style="width: 220px;">
												      <a-radio-group v-model="citySelect">
												        <a-radio-button value="a">
												          全部
												        </a-radio-button>
												        <a-radio-button value="b">
												          独立省
												        </a-radio-button>
												        <a-radio-button value="c">
												          独立市
												        </a-radio-button>
												      </a-radio-group>
												</div>
												<div style="width:300px;margin-top: 4px;" v-show="addEditShow && citySelect == 'b'">
													<area-select2 @change="selectArea" :province="[info.province]" :city="[info.city]" :district="[info.district]"  />
												</div>
												<div style="width:300px;margin-top: 4px;" v-show="addEditShow && citySelect == 'c'">
													<area-select3 @change="selectArea" :province="[info.province]" :city="[info.city]" :district="[info.district]" />
												</div>
											</div>
										</a-form-model-item>
									</a-col>
								</a-row>
						</a-col>
					</a-row>
				</a-form-model>

				<div class="model-footer">
					<div class="content-btns" v-if="isAdd">
						<a-button type="primary" class="marginr20" @click="addUser()">
							保存
						</a-button>
						<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
							取消
						</a-button>
					</div>
					<div class="left" v-if="!isAdd">
						<a-button type="primary" class="marginr20" @click="addUser('edit')">
							修改
						</a-button>
						<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
							取消
						</a-button>
					</div>
					<div class="right" v-if="!isAdd" :key="editIndex">
						<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
							上一条
						</a-button>
						<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
							下一条
						</a-button>
					</div>
				</div>
			</a-spin>
		</a-modal>




		<a-modal v-model="passwordShow" title="修改密码" width="400px" :footer="null">
			<a-form-model ref="rulepassForm" :model="password" :rules="passwordRules" :label-col="{ span: 7 }" :wrapper-col="{ span: 12 }"
			 style="margin: 0 auto; width: 80%">
				<a-row :gutter="24">
					<a-col :span="24">
						<a-form-model-item label="用户帐号：">
							<a-input v-model="password.admin_name" :disabled="true" placeholder="请输入用户帐号" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="密码：" prop="password">
							<a-input v-model="password.password" placeholder="请输入密码" type="password" />
						</a-form-model-item>
					</a-col>
					<a-col :span="24">
						<a-form-model-item label="确定密码：" prop="npassword">
							<a-input v-model="password.npassword" placeholder="请输入确定密码" type="password" />
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>

			<div class="model-footer">
				<div class="left">
					<a-button type="primary" class="marginr20" @click="updatePassword">
						修改
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="passwordShow = false">
						取消
					</a-button>
				</div>
				<div class="right" :key="editIndex">
					<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
						上一条
					</a-button>
					<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
						下一条
					</a-button>
				</div>
			</div>
		</a-modal>

	</div>
</template>

<script>
	import http from '../http'
	import moment from "moment";

	const columns = [{
			title: "ID",
			dataIndex: "admin_id",
			key: "65",
			width: 65
		},{
			title: "账号",
			dataIndex: "admin_name",
			key: "1"
		},
		{
			title: "联系人",
			dataIndex: "real_name",
			key: "2"
		},
		{
			title: "银行",
			dataIndex: "bank_type",
			key: "13",
			scopedSlots: {
				customRender: 'bank_type'
			},
		},
		{
			title: "类型",
			dataIndex: "type_name",
			key: "5"
		},
		{
			title: "城市",
			dataIndex: "province",
			key: "44",
			scopedSlots: {
				customRender: 'province'
			},
		},
		{
			title: "状态",
			dataIndex: "is_lock",
			scopedSlots: {
				customRender: 'is_lock'
			},
		},
		
	];


	import AreaSelect2 from "@/components/City2/index.vue";
	import AreaSelect3 from "@/components/City3/index.vue";

	export default {
		data() {
			return {
				//配置默认选中的省市区(依次是省市区的区域代码)
				defaultArea: ['32', '3205', '320571'],
				expand: false,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,

				show1: false,
				show2: false,
				show3: false,
				show4: false,

				// 添加与编辑的数据
				info: {
					admin_id: undefined,
					type_id: undefined,
					admin_name: '',
					real_name: '',
					contact: '',
					mobile: '',
					province: '',
					city: '',
					district: '',
					dept_id: undefined,
					role_id: undefined,
					bank_type: undefined,
					gender: 1
				},

				rules: {
					dept_id: [{
						required: true,
						message: '请选择所属部门',
						trigger: 'change'
					}, ],
					role_id: [{
						required: true,
						message: '请选择角色',
						trigger: 'change'
					}, ],
					type_id: [{
						required: true,
						message: '请选择机构类型',
						trigger: 'change'
					}, ],
					bank_type: [{
						required: true,
						message: '请选择银行类型',
						trigger: 'change'
					}, ],
					admin_name: [{
						required: true,
						message: '请输入用户帐号',
						trigger: 'blur'
					}, ],
					real_name: [{
						required: true,
						message: '请输入用户姓名',
						trigger: 'blur'
					}, ],
					contact: [{
						required: true,
						message: '请输入单位电话',
						trigger: 'blur'
					}, ],
					mobile: [{
						required: true,
						message: '请输入移动电话',
						trigger: 'blur'
					}, ],
					province: [{
						required: true,
						message: '请选择地址',
						trigger: 'blur'
					}, ],
				},

				// 搜索参数
				searchData: {
					admin_name: '',
					real_name: '',
					dept_id: 0, // 部门ID
					is_lock: 0, // 1锁定2正常
					page: 1, // 页码
				
				},
				// 多少条数据 * 10
				total_page: 0,
				userList: [],

				addEditShow: false,
				isAdd: false,

				// 选择的数据
				selectedRowDataList: [],
				selectedRowKeys: [],

				// 部门列表
				depList: [],
				roleList: [],

				bankTreeData: [],

				// 当前是第几条编辑信息
				editIndex: 0,

				detailLoading: false,

				passwordShow: false,
				// 添加与编辑的数据
				password: {
					admin_id: 0,
					admin_name: '',
					password: '',
					npassword: ''
				},
				passwordRules: {
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}, ],
					npassword: [{
						required: true,
						validator: this.validatePass,
						trigger: 'blur'
					}, ],
				},
				selectedArea: [],
				
				citySelect: 'a'
			};
		},
		computed: {
			count() {
				return this.expand ? 11 : 7;
			},
		},
		updated() {
			console.log("updated");
		},
		created() {
			this.getCateList()
			this.getBankTreeData()
			this.getRoleList()
		},
		mounted() {
			this.getUserList()

		},
		components: {
			AreaSelect2,
			AreaSelect3
		},
		methods: {
			moment,
			onChange(e) {
				console.log(`checked = ${e.target.checked}`);
			},
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {
					console.log("error", error);
					console.log("Received values of form: ", values);
				});
			},

			handleReset() {
				this.searchData = {
					admin_name: '',
					real_name: '',
					dept_id: 0, // 部门ID
					is_lock: 0, // 1锁定2正常
					page: 1, // 页码
				}
				this.getUserList()
			},

			toggle() {
				this.expand = !this.expand;
			},

			handleChange() {},

			handleSelectChange(value) {
				console.log(value);
				this.form.setFieldsValue({
					note: `Hi, ${value === "male" ? "man" : "lady"}!`,
				});
			},

			lockUser() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择用户');
					return
				}

				this.$confirm({
					title: '是否锁定选中用户?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						let selectDatas = []
						this.userList.forEach((v) => {
							if (this.selectedRowKeys.includes(v.admin_id)) {
								selectDatas.push(v.admin_id)
							}
						})
						http({
							method: 'post',
							url: '/api.admin.tolock/',
							data: {
								admin_id: selectDatas.join()
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('锁定用户成功');
								this.getUserList()
							} else {
								this.$message.error(res.data);
							}
						})
					}
				});
			},

			// 修改密码
			passwordUpdate() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择用户');
					return
				}
				this.password = {
					admin_id: 0,
					admin_name: '',
					password: '',
					npassword: ''
				}
				let selectDatas = []
				this.userList.forEach((v) => {
					if (this.selectedRowKeys.includes(v.admin_id)) {
						selectDatas.push(v)
					}
				})
				this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
				this.editIndex = 0
				this.password.admin_name = this.selectedRowDataList[this.editIndex].admin_name
				this.password.admin_id = this.selectedRowDataList[this.editIndex].admin_id
				this.passwordShow = true
			},

			// 删除
			deleteUser() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择用户');
					return
				}

				this.$confirm({
					title: '是否删除选中用户?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						let selectDatas = []
						this.userList.forEach((v) => {
							if (this.selectedRowKeys.includes(v.admin_id)) {
								selectDatas.push(v.admin_id)
							}
						})
						http({
							method: 'post',
							url: '/api.admin.del/',
							data: {
								admin_id: selectDatas.join()
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('删除用户成功');
								this.getUserList()
							} else {
								this.$message.error(res.data);
							}
						})
					}
				});
			},

			//选择地区之后的回调
			selectArea(selectedArea) {
				console.log(selectedArea, 'selectedAreaselectedArea')
				this.selectedArea = selectedArea
				this.info.province = selectedArea[0]
				this.info.city = selectedArea[1]
				this.info.district = selectedArea[2]
			},

			// 获取部门列表
			getCateList() {
				http({
					method: 'post',
					url: '/api.cate.list/',
					data: {
						type: 1,
						page: 1,
						parent_id: 0
					}
				}).then((res) => {
					this.depList = res.data.list
				})
			},
			// 获取角色列表
			getRoleList() {
				http({
					method: 'post',
					url: '/api.admin.role.list/',
					data: {
						page: 1,
						role_name: ''
					}
				}).then((res) => {
					this.roleList = res.data.list
				})
			},
			
			// 请求用户列表
			getUserList() {
				http({
					method: 'post',
					url: '/api.admin.list/',
					data: this.searchData
				}).then((res) => {
					this.userList = res.data.list
					this.total_page = res.data.total_page
				})
			},
			// 添加用户
			addUser(type) {
				console.log(this.selectedArea)
				// return
				this.$refs.ruleForm.validate(valid => {
					let cityDat = {
						province: this.selectedArea[0] || '',
						city: this.selectedArea[1] || ''
					}
					if (this.citySelect == 'a') {
						cityDat = {
							province: '',
							city: ''
						}
					} else if (this.citySelect == 'b') {
						if (this.selectedArea[0] == '') {
							this.$message.warning('请完善区域选择');
							return
						}
						cityDat = {
							province: this.selectedArea[0],
							city: ''
						}
					} else if (this.citySelect == 'c') {
						if (this.selectedArea[0] == '' || this.selectedArea[1] == '') {
							this.$message.warning('请完善区域选择');
							return
						}
					}
					console.log(cityDat, 'cityDatcityDat')
					if (valid) {
						http({
							method: 'post',
							url: '/api.admin.add/',
							data: {
								...this.info,
								bank_type: this.info.bank_type || 0,
								admin_id: this.info.admin_id || 0,
								...cityDat,
								district: '',
							}
						}).then((res) => {
							if (res.code == '200') {
								if (!type) {
									this.addEditShow = false
								}
								this.$message.success(type == 'edit' ? '修改用户信息成功' : '添加用户成功');
								this.getUserList()
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						this.$message.warning('请检查是否已经输入必填项!');
						return false;
					}
				});
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				console.log('selectedRowKeys changed: ', selectedRowKeys);
				this.selectedRowKeys = selectedRowKeys;
			},
			// 显示 添加与编辑 的 弹窗
			showAddEdit(type) {
				if (type == 'add') {
					this.citySelect = 'a'
					// 添加初始数据
					this.info = {
						admin_id: undefined,
						type_id: undefined,
						admin_name: '',
						real_name: '',
						contact: '',
						mobile: '',
						province: '',
						city: '',
						district: '',
						dept_id: undefined,
						bank_type: undefined,
						gender: 1,
						role_id: undefined,
					}
					this.isAdd = true
				} else if (type == 'edit') {
					// 判断是否已经选择数据
					if (this.selectedRowKeys.length == 0) {
						this.$message.warning('请先选择用户');
						return
					}

					let selectDatas = []
					this.userList.forEach((v) => {
						if (this.selectedRowKeys.includes(v.admin_id)) {
							selectDatas.push(v)
						}
					})
					this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
					this.editIndex = 0
					this.getWebTextDetail()
					this.isAdd = false
				}

				this.addEditShow = true
			},
			// 文章详情
			getWebTextDetail() {
				this.detailLoading = true
				http({
					method: 'post',
					url: '/api.admin.detail/',
					data: {
						admin_id: this.selectedRowDataList[this.editIndex].admin_id
					}
				}).then((res) => {
					this.info = res.data
					this.selectedArea = [this.info.province, this.info.city, this.info.district]
					if (this.info.province && this.info.city) {
						this.citySelect = 'c'
					}
					if (this.info.province && this.info.city == '') {
						this.citySelect = 'b'
					}
					if (this.info.province == '') {
						this.citySelect = 'a'
					}
					this.detailLoading = false
				})
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},
			// 点击上一条 或者 下一条、
			editToData(type) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				if (this.passwordShow) {
					this.password.admin_name = this.selectedRowDataList[this.editIndex].admin_name
					this.password.admin_id = this.selectedRowDataList[this.editIndex].admin_id
				} else {
					this.getWebTextDetail()
				}
			},
			validatePass(rule, value, callback) {
				if (this.password.password != this.password.npassword) {
					callback(new Error('两次密码不相同，请确定'));
				} else {
					callback();
				}
			},
			updatePassword() {
				this.$refs.rulepassForm.validate(valid => {
					
					if (valid) {
						this.password.admin_name = undefined
						http({
							method: 'post',
							url: '/api.admin.updatepwd/',
							data: this.password
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('密码修改成功');
								this.getUserList()
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						this.$message.warning('请检查是否已经输入必填项!');
						return false;
					}
				});
			},

			// 获取银行类型
			getBankTreeData() {
				http({
					method: 'post',
					url: '/api.bank.list/',
					data: {
						page: 1,
						parent_id: 0
					}
				}).then((res) => {
					this.bankTreeData = this.dataDealwith(res.data)
					console.log(this.bankTreeData)
				})
			},
			dataDealwith(treeData) {
				console.log(treeData)

				function setGrayNode(data) { //遍历树  获取id数组
					for (var i = 0; i < data.length; i++) {
						data[i] = {
							value: data[i].bank_id,
							key: data[i].bank_id,
							title: data[i].name,
							children: data[i].list || []
						} //子节点传给另一个函数专门用来改数据
						if (data[i].children && data[i].children.length) { // 如果当前节点有子节点，就递归调用一次
							setGrayNode(data[i].children);
						}
					}
				}

				setGrayNode(treeData)
				console.log(treeData)
				return treeData;
			},

			getBankName(type, name1) {
				console.log(name1)
				if (name1 != 3) {
					return ''
				}
				let da = this.bankTreeData
				let name = ''

				function getCity(arr, data) {
					for (let j = 0; j < data.length; j++) {
						if (arr[0] === data[j].value) {
							name = data[j].title
							return
						}
						if (name) {
							return
						}
						getCity(arr, data[j].children);
					}
				}
				getCity([type], da)
				console.log(name, 222)
				return name
			}
		},
	};
</script>
<style lang="less">
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}


	.tes {
		width: 90%;
		margin: 0 auto;
		border: 1px solid #e8e8e8;
		border-radius: 3px;

		.table-th {
			display: flex;
			align-items: center;
			border-bottom: 1px solid #e8e8e8;
			height: 30px;

			div {
				width: 33.33%;
				box-sizing: border-box;
				height: 30px;
				padding-left: 20px;
				font-weight: 700;
				border-right: 1px solid #e8e8e8;
				display: flex;
				align-items: center;

				&:last-child {
					border-right: none;
				}
			}
		}

		.taes {
			display: flex;
			align-items: center;

			&>div {
				width: 33.33%;
			}

			.chebox {
				overflow: hidden;
				box-sizing: border-box;
				height: 50px;
				display: flex;
				align-items: center;
				border-bottom: 1px solid #e8e8e8;

				&:last-child {
					border-bottom: none;
				}

				.chebox-item {
					box-sizing: border-box;
					padding-left: 20px;
				}
			}

			.left {
				height: 300px;
				border-right: 1px solid #e8e8e8;

				.chebox {
					height: 100%;
				}
			}

			.contet {
				height: 300px;
				border-right: 1px solid #e8e8e8;
			}

			.right {
				height: 300px;
			}
		}
	}
</style>
