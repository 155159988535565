<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="user" />
				<span>用户信息</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 投诉与建议 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">

			<a-form class="ant-advanced-search-form">
				<a-row :gutter="24">
					<a-col :span="6">
						<a-form-item :label="`类型：`">
							<a-select style="width: 120px" placeholder="请选择" v-model="searchData.type">
								<a-select-option :value="0"> 全部 </a-select-option>
								<a-select-option :value="1"> 建议 </a-select-option>
								<a-select-option :value="2">
									投诉
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>

					<a-col :span="7">
						<a-form-item :label="`提交时间：`">
							<a-range-picker :disabledDate="disabledDate" :ranges="{ '今天': [moment(), moment()] }" :key="pickerKey" @change="handleChangeDate" style="width: 120px" format="YYYY-MM-DD" />
						</a-form-item>
					</a-col>

					<a-col :span="6" :style="{
              textAlign: 'left',
              paddingLeft: '25px',
              marginTop: '6px',
            }">
						<a-button type="primary" @click="getList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">

				<div class="table-header-box">
					<div class="left">
						<h3 class="title">投诉与建议列表</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showDetail">
							详情
						</a-button>
						<a-button type="primary" class="marginr20" @click="deleteAudit">
							删除
						</a-button>
					</div>
				</div>
				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" :total="total_page * 10"
				 :data-source="list" rowKey="fb_id" size="small">

				</a-table>
			</div>
		</div>


		<a-modal v-model="detailShow" title="详细信息" :footer="null">
			<a-spin :spinning="detailLoading">
				<a-form :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" style="margin: 0 auto; width: 100%">
					<a-form-item label="联系人：" >
						<a-input v-model="info.real_name" :disabled="true"/>
					</a-form-item>
					<a-form-item label="联系方式：">
						<a-input v-model="info.contact" :disabled="true"/>
					</a-form-item>
					<a-form-item label="类型：">
						<a-input v-model="info.type_name" :disabled="true"/>
					</a-form-item>
					<a-form-item label="发生时间:">
						<a-input v-model="info.add_time" :disabled="true"/>
					</a-form-item>
					<a-form-item label="内容：">
						<a-textarea v-model="info.content" :disabled="true"/>
					</a-form-item>
					<a-form-item label="图片：">
						<div class="imgs">
							<img v-for="item in info.image" :src="item" alt="">
						</div>
					</a-form-item>
				</a-form>
				<div class="model-footer">
					<div class="left">
						<a-button type="primary" class="marginr20" html-type="submit" @click="detailShow = false">
							取消
						</a-button>
					</div>
					<div class="right">
						<a-button type="dashed"  :disabled="isEditBtn('prev')" @click="editToData('prev')"  class="" html-type="submit">
							上一条
						</a-button>
						<a-button type="dashed"  :disabled="isEditBtn('next')" @click="editToData('next')"  class="marginr20" html-type="submit">
							下一条
						</a-button>
					</div>
				</div>
			</a-spin>
		</a-modal>

	</div>
</template>

<script>
	import moment from 'moment';
	import http from '../http'

	const columns = [
		{
			title: "联系人",
			dataIndex: "real_name",
			key: "32"
		},
		{
			title: "联系方式",
			dataIndex: "contact",
			key: "33"
		},
		{
			title: "内容",
			dataIndex: "content",
			key: "2"
		},
		{
			title: "类型",
			dataIndex: "type_name",
			key: "13"
		},
		{
			title: "发生时间",
			dataIndex: "add_time",
			key: "12"
		},

	];
	export default {
		data() {
			return {
				expand: false,
				columns,

				detailShow: false,
				
				info: {},
				
				searchData: {
					page: 1,
					type: 0,
					start_date: '',
					end_date: ''
				},
				list: [],
				total_page: 0,

				// 表格选择的数据, 存储的是id
				selectedRowKeys: [],
				// 存储的是数据
				selectedRowDataList: [],
				// 当前是第几条编辑信息
				editIndex: 0,

				detailLoading: false,
				
				pickerKey: Math.random()
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			moment,
			disabledDate(current) {
			     return current && current >= moment().subtract("days"); //当天之前的不可选，不包括当天
			   //return current && current < moment().endOf(‘day’);当天之前的不可选，包括当天
			},
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {
					console.log("error", error);
					console.log("Received values of form: ", values);
				});
			},

			handleReset() {
				this.pickerKey = Math.random()
				this.searchData = {
					page: 1,
					type: 0,
					start_date: '',
					end_date: ''
				}
				this.getList();
			},

			toggle() {
				this.expand = !this.expand;
			},

			handleChange() {},

			handleSelectChange(value) {
				console.log(value);
				this.form.setFieldsValue({
					note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
				});
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				console.log('selectedRowKeys changed: ', selectedRowKeys);
				this.selectedRowKeys = selectedRowKeys;
			},
			// 列表
			getList() {
				http({
					method: 'post',
					url: '/api.user.feedback/',
					data: this.searchData
				}).then((res) => {
					this.list = res.data.list
					this.total_page = res.data.total_page
				})
			},
			// 日期解析
			handleChangeDate(date) {
				this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
				this.searchData.end_date = this.moment(date[1]).format('YYYY-MM-DD')
			},
			// 删除
			deleteAudit() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择投诉与建议');
					return
				}

				this.$confirm({
					title: '是否删除选中投诉与建议?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						let selectDatas = []
						this.list.forEach((v) => {
							if (this.selectedRowKeys.includes(v.fb_id)) {
								selectDatas.push(v.fb_id)
							}
						})
						http({
							method: 'post',
							url: '/api.user.fckdel/',
							data: {
								fb_id: selectDatas.join(),
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('删除投诉与建议成功');
								this.getList()
							} else {
								this.$message.error(res.data);
							}
						})
					}
				});
			},
			showDetail() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择投诉与建议');
					return
				}
				console.log(this.selectedRowKeys)
				let selectDatas = []
				this.list.forEach((v) => {
					if (this.selectedRowKeys.includes(v.fb_id)) {
						selectDatas.push(v)
					}
				})
				this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
				console.log(this.selectedRowDataList)
				this.editIndex = 0
				// 获取文章详情
				this.getDetail()
				this.detailShow = true
			},
			// 详情
			getDetail() {
				this.detailLoading = true
				http({
					method: 'post',
					url: '/api.user.fckdetail/',
					data: {
						fb_id: this.selectedRowDataList[this.editIndex].fb_id
					}
				}).then((res) => {
					this.info = res.data
					this.detailLoading = false
				})
			},
			// 点击上一条 或者 下一条、
			editToData(type) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				this.getDetail()
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},
		},
	};
</script>
<style>
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}
	
	.imgs {
		display: flex;
	}
	.imgs img {
		width: 140px;
		height: 200px;
	}
</style>
