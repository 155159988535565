<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="setting" />
				<span>系统管理</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 系统配置 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box baff">
			<a-form-model ref="rulepassForm" :label-col="{ span: 10 }" :wrapper-col="{ span: 12 }" style="width: 500px">
				<a-row :gutter="24">
					<a-col :span="24" v-for="(item, index) in setttingList">
						<a-form-model-item :label="texts[item.variable].title">
							<a-input v-model="item.value" v-if="texts[item.variable].type == 'input'" placeholder="" type="text" />
							<a-radio-group v-model="item.value" v-if="texts[item.variable].type == 'radio'" name="radioGroup" :default-value="1">
								<a-radio value="0">
									关闭
								</a-radio>
								<a-radio value="1">
									开启
								</a-radio>
							</a-radio-group>
						</a-form-model-item>
					</a-col>
					<a-col :span="24" style="margin-top: 40px;">
						<a-button type="primary" class="marginr20" style="margin-left: 220px" html-type="submit" @click="saveSetting">
							确定
						</a-button>
					</a-col>
				</a-row>
			</a-form-model>
		</div>


	</div>
</template>

<script>
	// 系统名称 文本框
	// 接口地址 文本框
	// 开启纸币兑换硬币 01
	// 开启硬币兑换纸币 01
	// 开启纸币大额兑换小额 01
	// 开启纸币小额兑换大额 01
	// 服务电话 文本框

	import http from '../http'
	export default {
		data() {
			return {
				texts: {
					sys_name: {
						title: '系统名称',
						type: 'input',
					},
					sys_url: {
						title: '接口地址',
						type: 'input',
					},
					appid: {
						title: 'appid',
						type: 'input',
					},
					secret: {
						title: 'secret',
						type: 'input',
					},
					is_able_typeo: {
						title: '开启纸币兑换硬币',
						type: 'radio',
					},
					is_able_typet: {
						title: '开启硬币兑换纸币',
						type: 'radio',
					},
					is_able_types: {
						title: '开启纸币大额兑换小额',
						type: 'radio',
					},
					is_able_typef: {
						title: '开启纸币小额兑换大额',
						type: 'radio',
					},
					is_able_typev: {
						title: '开启纸币需求信息',
						type: 'radio',
					},
					service_tel: {
						title: '服务电话',
						type: 'input',
					},
				},
				setttingList: []
			};
		},
		mounted() {
			this.getSettingList()
		},
		methods: {
			getSettingList() {
				http({
					method: 'post',
					url: '/api.config.sysconfig/',
				}).then((res) => {
					if (res.code == '200') {
						this.setttingList = res.data
					} else {
						this.$message.error('系统配置获取失败');
					}
				})
			},
			saveSetting () {
				http({
					method: 'post',
					url: '/api.config.sysadd/',
					data: {content: JSON.stringify(this.setttingList)}
				}).then((res) => {
					if (res.code == '200') {
						this.$message.success('设置系统参数成功');
					} else {
						this.$message.error('系统配置获取失败');
					}
				})
			},
			validatePass(rule, value, callback) {
				if (this.password.password != this.password.npassword) {
					callback(new Error('两次密码不相同，请确定'));
				} else {
					callback();
				}
			},
			updatePassword() {
				this.$refs.rulepassForm.validate(valid => {
					if (valid) {
						http({
							method: 'post',
							url: '/api.admin.update.userpwd/',
							data: this.password
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('密码修改成功');
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						this.$message.warning('请检查是否已经输入必填项!');
						return false;
					}
				});
			},
		},
	};
</script>
<style lang="less">
	.baff {
		background-color: #fff;
		margin-top: 16px;
		padding-bottom: 100px;
		padding-top: 50px;
	}

	.nesw {
		margin: 0px;
		font-size: 12px;
		color: red;
		line-height: 16px;
	}
</style>
