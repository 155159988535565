import axios from 'axios'
const WEBURL = window.WEBURL
const http = ({method, url, data, ContentType}) => {
    let par = {
        method: method,
        url: WEBURL + url,
    }
	
    if (method == 'post') {
        par['data'] = data
    }

    if (localStorage.getItem("token")) {
        par['headers'] = {
            ...par['headers'],
            'token': localStorage.getItem("token")
        }
    }

    if (method == 'get') {
        par['params'] = data
    }
	console.log(par)
    return axios(par).then((res) => {
        if (res.data.code == 201) {
			if (window.location.href.indexOf('#/login') >= 0) {
			} else {
				window.location.href = '#/login'
			}
        }
		return res.data
	})
}

export default http