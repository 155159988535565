<template>
  <div class="home">
    <a-breadcrumb>
      <a-breadcrumb-item href="">
        <a-icon type="home" />
      </a-breadcrumb-item>
      <a-breadcrumb-item href="">
        <a-icon type="user" />
        <span>监控管理</span>
      </a-breadcrumb-item>
      <a-breadcrumb-item> 设备管理 </a-breadcrumb-item>
    </a-breadcrumb>

    <div class="search-list-box">
      <a-form
        class="ant-advanced-search-form"
        :form="form"
        @submit="handleSearch"
      >
        <a-row :gutter="24">
          <a-col :span="6">
            <a-form-item :label="`银行名称：`">
              <a-input placeholder="请输入设备编号" />
            </a-form-item>
          </a-col>

          <a-col :span="6">
            <a-form-item :label="`银行编号：`">
              <a-input placeholder="请输入设备编号" />
            </a-form-item>
          </a-col>
         
          <a-col
            :span="6"
            :style="{
              textAlign: 'left',
              paddingLeft: '35px',
              marginTop: '6px',
            }"
          >
            <a-button type="primary" html-type="submit"> 查询 </a-button>
            <a-button :style="{ marginLeft: '8px' }" @click="handleReset">
              重置
            </a-button>
          </a-col>
        </a-row>
      </a-form>
      <div class="search-result-list">
        <div class="table-header-box">
          <div class="left">
            <h3 class="title">银行卡bin列表</h3>
          </div>
          <div class="right">
            <a-button
              type="primary"
              class="marginr20"
              html-type="submit"
              @click="show1 = true"
            >
              新增
            </a-button>
            <a-button
              type="primary"
              class="marginr20"
              html-type="submit"
              @click="show1 = true"
            >
              修改
            </a-button>
          </div>
        </div>
        <a-table
          :row-selection="rowSelection"
          :columns="columns"
          :data-source="data"
          :scroll="{ x: 1300, y: 300 }"
          :show-total="(total) => `Total ${total} items`"
          size="small"
        >
         
        </a-table>
      </div>
    </div>

    <a-modal v-model="show1" title="添加资源信息" :footer="null" @ok="handleOk">
      <a-form
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        @submit="handleSubmit"
        style="margin: 0 auto; width: 80%"
      >
        <a-form-item label="编号：">
          <a-input
            v-decorator="[
              'value1',
              {
                rules: [{ required: true, message: 'Please input your note!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="银行编号：">
          <a-input
            v-decorator="[
              'value2',
              {
                rules: [{ required: true, message: 'Please input your note!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="所属银行：">
          <a-input
            v-decorator="[
              'value3',
              {
                rules: [{ required: true, message: 'Please input your note!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="是否启用：">
          <a-select
                v-decorator="[
                  'select',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Please select your country!',
                      },
                    ],
                  },
                ]"
                placeholder=""
              >
                <a-select-option value="china"> China </a-select-option>
                <a-select-option value="usa"> U.S.A </a-select-option>
              </a-select>
        </a-form-item>
        <a-form-item label="交易次数：">
          <a-input
            v-decorator="[
              'value5',
              {
                rules: [{ required: true, message: 'Please input your note!' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="银行卡bin：">
          <a-input
            v-decorator="[
              'value6',
              {
                rules: [{ required: true, message: 'Please input your note!' }],
              },
            ]"
          />
        </a-form-item>
      </a-form>

      <div class="model-footer">
        <div class="left">
          <a-button
            type="primary"
            class=""
            html-type="submit"
            @click="show1 = false"
          >
            添加
          </a-button>
          <a-button
            type="primary"
            class="marginr20"
            html-type="submit"
            @click="show1 = false"
          >
            取消
          </a-button>
        </div>
        <div class="right">
          <a-button type="dashed" class="" html-type="submit">
            上一条
          </a-button>
          <a-button type="dashed" class="marginr20" html-type="submit">
            下一条
          </a-button>
        </div>
      </div>
    </a-modal>

  
  </div>
</template>

<script>
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
const columns = [
  { title: "编号", dataIndex: "address", key: "1" },
  { title: "银行编号", dataIndex: "address", key: "2" },
  { title: "所属银行", dataIndex: "address", key: "3" },
  { title: "是否启用", dataIndex: "address", key: "4" },
  { title: "交易次数", dataIndex: "address", key: "5" },
  { title: "银行卡bin", dataIndex: "address", key: "6" },
  { title: "创建日期", dataIndex: "address", key: "7" },

     
];
const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "2",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "3",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "4",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "5",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "6",
    name: "John Brown",
    age: 32,
    address: "New York Park",
  },
  {
    key: "7",
    name: "Jim Green",
    age: 40,
    address: "London Park",
  },
];
export default {
  data() {
    return {
      expand: false,
      form: this.$form.createForm(this, { name: "advanced_search" }),
      data,
      columns,
      rowSelection,

      show1: false,
    };
  },
  computed: {
    count() {
      return this.expand ? 11 : 7;
    },
  },
  updated() {
    console.log("updated");
  },
  methods: {
    handleSearch(e) {
      e.preventDefault();
      this.form.validateFields((error, values) => {
        console.log("error", error);
        console.log("Received values of form: ", values);
      });
    },

    handleReset() {
      this.form.resetFields();
    },

    toggle() {
      this.expand = !this.expand;
    },

    handleChange() {},

    handleSelectChange(value) {
      console.log(value);
      this.form.setFieldsValue({
        note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
      });
    },
  },
};
</script>
<style>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

.search-list-box .ant-form {
  max-width: none;
}
.search-list-box .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
</style>
