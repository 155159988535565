<template>
	<div style="display: flex;width: 150px;">
		<a-cascader :fieldNames="{ label: 'name', value: 'name', children: 'children' }" :options="data1" v-model="selectedValues"
		 @change="onChange1" placeholder="请选择省" style="180px;margin-right: 10px;" />
	</div>
</template>
<script>
	import areaData from "./areadata";
	export default {
		name: "areaSelect",
		props: {
			placeholder: {
				type: String,
				default: "请选择省",
			},
			defaultValue: {
				type: Array,
				default () {
					return [];
				},
			},
			province: {
				type: Array,
				default () {
					return [];
				},
			},
		},
		data() {
			return {
				//地区数据
				areaData,
				//选择的数据
				selectedValues: [],

				data1: [],

				data2_value: []
			};
		},
		mounted() {
			this.filterData()
			if (this.province.length && this.province[0] != '') {
				this.selectedValues = this.province;
			} else {
				this.selectedValues = []
			}
		},
		watch: {
			province(newValue) {
				if (newValue.length && newValue[0]) {
					this.selectedValues = newValue;
				} else {
					this.selectedValues = [];
					this.selectedValues2 = []
					this.selectedValues3 = []
				}
			},
		},
		methods: {
			ts2 () {
				if (this.data2.length == 0) {
					this.$message.error('请先选择省');
				}
			},
			ts3 () {
				if (this.data3.length == 0) {
					this.$message.error('请先选择市');
				}
			},
			//选择好之后的回调
			onChange1(value) {
				this.selectedValues2 = []
				this.selectedValues3 = []
				this.$emit("change", [this.selectedValues[0], this.selectedValues2[0], this.selectedValues3[0]]);
			},
			onChange2(value) {
				this.selectedValues3 = []
				this.$emit("change", [this.selectedValues[0], this.selectedValues2[0], this.selectedValues3[0]]);
				this.filter2Data()
			},
			onChange3(value) {
				this.$emit("change", [this.selectedValues[0], this.selectedValues2[0], this.selectedValues3[0]]);
			},
			filterData() {
				this.data1 = areaData.map((v) => {
					return {
						"code": v.code,
						"name": v.name,
						"children": undefined
					}
				})
			},
			filter1Data() {
				let data2 = []
				areaData.forEach((v) => {
					if (v.name == this.selectedValues[0]) {
						this.data2_value = v.children
						data2 = v.children.map((v) => {
							return {
								"code": v.code,
								"name": v.name,
								"children": undefined
							}
						})
					}
				})
				this.data2 = data2
			},
			filter2Data() {
				let data3 = []
				this.data2_value.forEach((v) => {
					if (v.name == this.selectedValues2[0]) {
						data3 = v.children
					}
				})
				this.data3 = data3
			}
		},
	};
</script>
<style lang="scss" scoped>
</style>
