<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="desktop" />
				<span>监控功能</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 出入钞统计 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form" :form="form" @submit="handleSearch">
				<a-row :gutter="24">
					<a-col :span="5">
						<a-form-item :label="`所属银行：`">
							<a-tree-select style="width: 100%" placeholder="请选择银行" v-model="searchData.bank_id" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							 allow-clear :tree-data="bankTreeData">
								<span v-if="key == '0'" slot="title" slot-scope="{ key, value }">
									请选择银行类型
								</span>
							</a-tree-select>
						</a-form-item>
					</a-col>
					<a-col :span="5">
						<a-form-item :label="`网点名称：`">
							<a-select style="width: 120px" v-model="searchData.node_id" placeholder="请选择网点名称">
								<a-select-option v-for="item in outletsList" :value="item.node_id"> {{item.name}} </a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
					<a-col :span="7">
						<a-form-item :label="`选择日期：`">
							<a-range-picker  :disabledDate="disabledDate" :ranges="{ '今天': [moment(), moment()] }" :key="pickerKey" @change="handleChangeDate" style="width: 120px" format="YYYY-MM-DD" />
						</a-form-item>
					</a-col>

					<a-col :span="6" :style="{
              textAlign: 'left',
              paddingLeft: '25px',
              marginTop: '6px',
            }">
						<a-button type="primary" @click="getList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">报表列表</h3>
					</div>
					<div class="right">

					</div>
				</div>
				<a-table :columns="columns" @change="handleTableChange" :pagination="{total: total_page * 10}" :data-source="list"
				 :scroll="{ x: 2600 }" rowKey="tran_id" size="small">

				</a-table>
			</div>
		</div>


	</div>
</template>

<script>
	import moment from 'moment';

	const columns = [{
			title: "网点名称",
			dataIndex: "node_name",
			key: "225"
		},

		{
			title: "入钞总金额",
			dataIndex: "in_amount",
			key: "1312",
			align: 'center'
		},
		{
			title: "出钞总金额",
			dataIndex: "out_amount",
			key: "1312",
			align: 'center'
		},
		{
			title: "纸币找零箱(100元)总额",
			dataIndex: "notes_changebox10000",
			key: "1312",
			align: 'center'
		},
		{
			title: "纸币找零箱(50元)总额",
			dataIndex: "notes_changebox5000",
			key: "1312",
			align: 'center'
		},
		{
			title: "纸币找零箱(20元)总额",
			dataIndex: "notes_changebox2000",
			key: "1312",
			align: 'center'
		},
		{
			title: "纸币找零箱(10元)总额",
			dataIndex: "notes_changebox1000",
			key: "1312",
			align: 'center'
		},
		{
			title: "纸币找零箱(5元)总额",
			dataIndex: "notes_changebox500",
			key: "1312",
			align: 'center'
		},


		{
			title: "硬币找零箱(1元)总额",
			dataIndex: "coin_changebox100",
			key: "1312",
			align: 'center'
		},

		{
			title: "硬币找零箱(5角)总额",
			dataIndex: "coin_changebox50",
			key: "1312",
			align: 'center'
		},



		{
			title: "硬币找零箱(1角)总额",
			dataIndex: "coin_changebox10",
			key: "1312",
			align: 'center'
		},

		{
			title: "硬币回收箱(1元)总额",
			dataIndex: "coin_collectionbox100",
			key: "1312",
			align: 'center'
		},



		{
			title: "硬币找回收箱(5角)总额",
			dataIndex: "coin_collectionbox50",
			key: "1312",
			align: 'center'
		},

		{
			title: "硬币回收箱(1角)总额",
			dataIndex: "coin_collectionbox10",
			key: "1312",
			align: 'center'
		},
		{
			title: "所属银行",
			dataIndex: "bank_name",
			key: "1234"
		},


	];
	import http from '../http'
	export default {
		data() {
			return {
				expand: false,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,
				searchData: {
					page: 1,
					node_id: undefined,
					bank_id: undefined,
					start_date: '',
					end_date: ''
				},
				list: [],
				total_page: 0,

				// 表格选择的数据, 存储的是id
				selectedRowKeys: [],

				pickerKey: Math.random(),
				bankTreeData: [],
				outletsList: [],
			};
		},
		computed: {
			count() {
				return this.expand ? 11 : 7;
			},
		},
		updated() {
			console.log("updated");
		},
		created() {
			this.getBankTreeData()
			this.getOutletsList()
			this.getList()
		},
		methods: {
			moment,
			disabledDate(current) {
			     return current && current >= moment().subtract("days"); //当天之前的不可选，不包括当天
			   //return current && current < moment().endOf(‘day’);当天之前的不可选，包括当天
			},
			handleTableChange(pagination, filters, sorter) {
				console.log(pagination, filters, sorter)
				this.searchData.page = pagination.current
				this.getList();
			},
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {
					console.log("error", error);
					console.log("Received values of form: ", values);
				});
			},

			// 表格选择
			onSelectChange(selectedRowKeys) {
				console.log('selectedRowKeys changed: ', selectedRowKeys);
				this.selectedRowKeys = selectedRowKeys;
			},

			handleReset() {
				this.pickerKey = Math.random()
				this.searchData = {
					page: 1,
					node_id: undefined,
					bank_id: undefined,
					start_date: '',
					end_date: ''
				}
				this.getList()
			},

			// 日期解析
			handleChangeDate(date) {
				this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
				this.searchData.end_date = this.moment(date[1]).format('YYYY-MM-DD')
			},

			// 列表
			getList() {
				http({
					method: 'post',
					url: '/api.transaction.report/',
					data: {
						...this.searchData,
						node_id: this.searchData.node_id || 0,
						bank_id: this.searchData.bank_id || 0
					}
				}).then((res) => {
					this.list = res.data.list
					this.total_page = res.data.total_page
				})
			},

			// 获取网点
			getOutletsList() {
				http({
					method: 'post',
					url: '/api.bank.node.list/',
					data: {
						page: 1,
						name: '',
						bank_id: 0
					}
				}).then((res) => {
					this.outletsList = res.data.list
				})
			},
			// 获取银行类型
			getBankTreeData() {
				http({
					method: 'post',
					url: '/api.bank.list/',
					data: {
						page: 1,
						parent_id: 0
					}
				}).then((res) => {
					this.bankTreeData = this.dataDealwith(res.data)
				})
			},
			dataDealwith(treeData) {
				function setGrayNode(data) { //遍历树  获取id数组
					for (var i = 0; i < data.length; i++) {
						data[i] = {
							value: data[i].bank_id,
							key: data[i].bank_id,
							title: data[i].name,
							children: data[i].list || []
						} //子节点传给另一个函数专门用来改数据
						if (data[i].children && data[i].children.length) { // 如果当前节点有子节点，就递归调用一次
							setGrayNode(data[i].children);
						}
					}
				}

				setGrayNode(treeData)
				console.log(treeData)
				return treeData;
			},
		},
	};
</script>
<style>
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}
</style>
