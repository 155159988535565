<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="desktop" />
				<span>监控功能</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 设备管理 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form" >
				<a-row :gutter="24">
					<a-col :span="7">
						<a-form-item :label="`所属银行：`">
							<a-tree-select style="width: 100%" placeholder="请选择银行" v-model="searchData.bank_id" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
							 allow-clear :tree-data="bankTreeData">
								<span v-if="key == '0'" slot="title" slot-scope="{ key, value }">
									请选择银行类型
								</span>
							</a-tree-select>
						</a-form-item>
					</a-col>
					<a-col :span="7">
						<a-form-item :label="`网点名称：`">
							<a-select style="width: 120px" v-model="searchData.node_id" placeholder="请选择网点名称">
								<a-select-option v-for="item in outletsList" :value="item.node_id"> {{item.name}} </a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
					<a-col :span="7" :style="{
					  paddingRight: '35px',
					  marginTop: '6px',
					}">
						<a-button type="primary" @click="getEquipmentList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">设备信息列表</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showAddEdit('add')">
							新增
						</a-button>
						<a-button type="primary" class="marginr20" @click="showAddEdit('edit')">
							详情
						</a-button>
						<a-button type="primary" class="marginr20" @click="deleteEq">
							删除
						</a-button>
					</div>
				</div>
				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" @change="handleTableChange"
				 :pagination="{total: total_page * 10}"
				 :data-source="List" rowKey="id" size="small">
					<span slot="province" slot-scope="province, data" >
						{{data.province}} {{data.district}} {{data.city}} {{data.address}}
					</span>
				</a-table>
			</div>
		</div>

		<a-modal v-model="addEditShow" title="新增设备" width="1000px" :footer="null" >
			<a-spin :spinning="detailLoading">
			<a-form-model ref="ruleForm" :model="info" :rules="rules" :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }" style="margin: 0 auto; width: 90%">
				<a-row :gutter="24">
					<a-col :span="12">
						<a-row :gutter="24">
							<a-col :span="24">
								<a-form-model-item label="appid：" v-if="info.appid">
									<a-input v-model="info.appid" :disabled="info.appid"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="secret：" v-if="info.appid">
									<a-input v-model="info.secret" :disabled="info.appid"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="编号：" prop="number">
									<a-input v-model="info.number" placeholder="请输入编号" />
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="设备名称：" prop="name">
									<a-input v-model="info.name" placeholder="请输入设备名称" />
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="设备类型：" prop="type_id">
									<a-select style="width: 100%" v-model="info.type_id" placeholder="请选择设备类型">
										<a-select-option v-for="item in equipment1List" :value="item.cate_id"> {{item.cate_name}} </a-select-option>
									</a-select>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="所属银行：" prop="bank_id">
									<a-tree-select style="width: 100%" placeholder="请选择银行" v-model="info.bank_id" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
									 allow-clear :tree-data="bankTreeData">
										<span v-if="key == '0'" slot="title" slot-scope="{ key, value }">
											请选择银行类型
										</span>
									</a-tree-select>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="网点名称：" prop="node_id">
									<a-select style="width: 100%" v-model="info.node_id" placeholder="请选择网点名称">
										<a-select-option v-for="item in outletsList" :value="item.node_id"> {{item.name}} </a-select-option>
									</a-select>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="厂商：" prop="factory">
									<a-select style="width: 100%" v-model="info.factory" placeholder="请选择厂商">
										<a-select-option v-for="item in vendorList" :value="item.cate_id"> {{item.cate_name}} </a-select-option>
									</a-select>
								</a-form-model-item>
							</a-col>
						</a-row>
					</a-col>
					<a-col :span="12">
						<a-row :gutter="24">
							<a-col :span="24">
								<a-form-model-item label="城市：" prop="province">
									<area-select @change="selectArea"   :default-value="[info.province, info.city, info.district]" />
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="详细地址：" prop="address">
									<a-input v-model="info.address" placeholder="请输入详细地址"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="联系人：" prop="real_name">
									<a-input v-model="info.real_name" placeholder="请输入联系人"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="联系方式：" prop="contact">
									<a-input v-model="info.contact" placeholder="请输入联系方式"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="营业时间：" prop="open_hours">
									<a-input v-model="info.open_hours" placeholder="请输入营业时间"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="无线流量卡号：" prop="wire_mac">
									<a-input v-model="info.wire_mac" placeholder="请输入无线流量卡号"/>
								</a-form-model-item>
							</a-col>
						</a-row>
					</a-col>
					
				</a-row>
			</a-form-model>
			<div class="model-footer">
				<div class="content-btns" v-if="isAdd">
					<a-button type="primary" class="marginr20" html-type="submit" @click="addEq()">
						保存
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="left" v-if="!isAdd">
					<a-button type="primary" class="marginr20" html-type="submit" @click="updateEq">
						修改
					</a-button>
					<a-button class="marginr20" html-type="submit" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="right" v-if="!isAdd" :key="editIndex">
					<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
						上一条
					</a-button>
					<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
						下一条
					</a-button>
				</div>
			</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	const columns = [
		{
			title: "所属银行",
			dataIndex: "bank_name",
			key: "4"
		},
		{
			title: "网点名称",
			dataIndex: "node_name",
			key: "4"
		},
		{
			title: "银行地址",
			dataIndex: "name",
			scopedSlots: {
				customRender: 'province'
			},
			key: "2"
		},
		
		{
			title: "联系人",
			dataIndex: "real_name",
			key: "6"
		},
		{
			title: "联系电话",
			dataIndex: "contact",
			key: "7"
		},
		{
			title: "设备厂家",
			dataIndex: "factory",
			key: "8"
		},
		{
			title: "无线流量卡号",
			dataIndex: "wire_mac",
			key: "8"
		},
	];
	import AreaSelect from "@/components/City/index.vue";
	import http from '../http'
	export default {
		data() {
			return {
				expand: false,
				form: this.$form.createForm(this, {
					name: "advanced_search"
				}),
				columns,

				show1: false,
				show2: false,
				show3: false,

				searchData: {
					node_id: undefined,
					bank_id: undefined,
					page: 1,
				},
				List: [],
				total_page: 0,
				
				// 添加与编辑的数据
				info: {
					number: '',
					name: '',
					type_id: undefined,
					bank_id: undefined,
					node_id: undefined,
					factory: undefined,
					real_name: '',
					contact: '',
					open_hours: '',
					province: '',
					city: '',
					district: '',
					address: '',
					wire_mac: ''
				},
				rules: {
					number: [{
						required: true,
						message: '请输入设备编号',
						trigger: 'blur'
					}, ],
					name: [{
						required: true,
						message: '请输入设备名称',
						trigger: 'blur'
					}, ],
					real_name: [{
						required: true,
						message: '请输入联系人',
						trigger: 'blur'
					}, ],
					contact: [{
						required: true,
						message: '请输入联系方式',
						trigger: 'blur'
					}, ],
					province: [{
						required: true,
						message: '请选择城市',
						trigger: 'change'
					}, ],
					address: [{
						required: true,
						message: '请输入设备详细放置地址',
						trigger: 'change'
					}, ],
					open_hours: [{
						required: true,
						message: '请输入营业时间',
						trigger: 'change'
					}, ],
					
					type_id: [{
						required: true,
						message: '请选择设备类型',
						trigger: 'change'
					}, ],
					bank_id: [{
						required: true,
						message: '请选择银行',
						trigger: 'change'
					}, ],
					node_id: [{
						required: true,
						message: '请选择网点',
						trigger: 'change'
					}, ],
					factory: [{
						required: true,
						message: '请选择厂商',
						trigger: 'change'
					}, ],
					wire_mac: [{
						required: true,
						message: '请输入无线流量卡号',
						trigger: 'change'
					}, ],
					
				},
				// 表格选择的数据, 存储的是id
				selectedRowKeys: [],
				// 存储的是数据
				selectedRowDataList: [],
				// 当前是第几条编辑信息
				editIndex: 0,
				
				// 编辑与添加
				addEditShow: false,
				// 是否添加状态
				isAdd: false,
				
				
				bankTreeData: [],
				vendorList: [],
				equipment1List: [],
				outletsList: [],
				
				detailLoading: false
			};
		},
		computed: {
			count() {
				return this.expand ? 11 : 7;
			},
		},
		components: {
			AreaSelect
		},
		mounted() {
			this.getEquipmentList()
		},
		created () {
			this.getVendorList()
			this.getEquipment1List()
			this.getOutletsList()
			this.getBankTreeData()
		},
		methods: {
			handleSearch(e) {
				e.preventDefault();
				this.form.validateFields((error, values) => {
					console.log("error", error);
					console.log("Received values of form: ", values);
				});
			},

			handleReset() {
				this.searchData = {
					node_id: undefined,
					bank_id: undefined,
					page: 1,
				}
				this.getEquipmentList()
			},

			toggle() {
				this.expand = !this.expand;
			},

			handleChange() {},

			handleSelectChange(value) {
				console.log(value);
				this.form.setFieldsValue({
					note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
				});
			},
			
			//选择地区之后的回调
			selectArea(selectedArea) {
				this.info.province = selectedArea[0]
				this.info.city = selectedArea[1]
				this.info.district = selectedArea[2]
			},
			
			deleteEq() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择设备');
					return
				}
			
				this.$confirm({
					title: '是否删除选中设备?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk : () => {
						let selectDatas = []
						this.List.forEach((v) => {
							if(this.selectedRowKeys.includes(v.id)) {
								selectDatas.push(v.id)
							}
						})
						http({
							method: 'post',
							url: '/api.device.del/',
							data: {
								id: selectDatas.join(),
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('删除设备成功');
								this.getEquipmentList()
							} else {
								this.$message.error(res.data);
							}
						})
					}
				});
			
			
			},
			// 表格分页
			handleTableChange(pagination, filters, sorter) {
				console.log(pagination, filters, sorter)
				this.searchData.page = pagination.current
				this.getEquipmentList();
			},
			// 列表
			getEquipmentList() {
				http({
					method: 'post',
					url: '/api.device.list/',
					data: {...this.searchData, node_id: this.searchData.node_id || 0, bank_id: this.searchData.bank_id || 0}
				}).then((res) => {
					this.List = res.data.list
					this.total_page = res.data.total_page
				})
			},
			
			// 点击上一条 或者 下一条、
			editToData(type) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				this.getDetail()
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				console.log('selectedRowKeys changed: ', selectedRowKeys);
				this.selectedRowKeys = selectedRowKeys;
			},
			// 显示 添加与编辑 的 弹窗
			showAddEdit(type) {
				if (type == 'add') {
					// 添加初始数据
					this.info = {
						number: '',
						name: '',
						type_id: undefined,
						bank_id: undefined,
						node_id: undefined,
						factory: undefined,
						real_name: '',
						contact: '',
						open_hours: '',
						province: '',
						city: '',
						district: '',
						address: '',
						wire_mac: ''
					}
					this.isAdd = true
				} else if (type == 'edit') {
					// 判断是否已经选择数据
					if (this.selectedRowKeys.length == 0) {
						this.$message.warning('请先选择设备');
						return
					}
			
					let selectDatas = []
					this.List.forEach((v) => {
						if (this.selectedRowKeys.includes(v.id)) {
							selectDatas.push(v)
						}
					})
					this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
					this.editIndex = 0
					// 获取详情
					this.getDetail()
					this.isAdd = false
				}
				
				this.addEditShow = true
			},
			// 详情
			getDetail() {
				console.log(this.selectedRowDataList)
				this.detailLoading = true
				http({
					method: 'post',
					url: '/api.device.detail/',
					data: {
						id: this.selectedRowDataList[this.editIndex].id
					}
				}).then((res) => {
					this.info = res.data
					this.detailLoading = false
				})
			},
			// 添加
			addEq(type) {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						http({
							method: 'post',
							url: '/api.device.add/',
							data: {
								id: 0,
								type: 0,
								...this.info
							}
						}).then((res) => {
							if (res.code == '200') {
								if (!type) {
									this.addEditShow = false
								}
								this.$message.success(type == 'edit' ? '修改设备信息成功' : '添加设备成功');
								this.getEquipmentList()
							} else {
								this.$message.error(res.message);
							}
						})
					} else {
						this.$message.warning('请检查是否已经输入必填项!');
						return false;
					}
				});
			},
			// 更新
			updateEq() {
				this.addEq('edit')
			},
			
			// 获取厂商
			getVendorList () {
				http({
					method: 'post',
					url: '/api.cate.list/',
					data: {
						page: 1,
						type: 4,
						parent_id: 0
					}
				}).then((res) => {
					this.vendorList = res.data.list
				})
			},
			// 获取设备类型
			getEquipment1List () {
				http({
					method: 'post',
					url: '/api.cate.list/',
					data: {
						page: 1,
						type: 2,
						parent_id: 0
					}
				}).then((res) => {
					this.equipment1List = res.data.list
				})
			},
			// 获取网点
			getOutletsList () {
				http({
					method: 'post',
					url: '/api.bank.node.list/',
					data: {
						page: 1,
						name: '',
						bank_id: 0
					}
				}).then((res) => {
					this.outletsList = res.data.list
				})
			},
			// 获取银行类型
			getBankTreeData() {
				http({
					method: 'post',
					url: '/api.bank.list/',
					data: {
						page: 1,
						parent_id: 0
					}
				}).then((res) => {
					this.bankTreeData = this.dataDealwith(res.data)
					console.log(this.bankTreeData)
				})
			},
			dataDealwith(treeData) {
				function setGrayNode(data) { //遍历树  获取id数组
					for (var i = 0; i < data.length; i++) {
						data[i] = {
							value: data[i].bank_id,
							key: data[i].bank_id,
							title: data[i].name,
							children: data[i].list || []
						} //子节点传给另一个函数专门用来改数据
						if (data[i].children && data[i].children.length) { // 如果当前节点有子节点，就递归调用一次
							setGrayNode(data[i].children);
						}
					}
				}
			
				setGrayNode(treeData)
				console.log(treeData)
				return treeData;
			},
		},
	};
</script>
<style>
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}
</style>
