<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="setting" />
				<span>系统管理</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 路由管理 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="tree-wrap">
			<div class="detf">
				<p class="title-p">路由管理</p>
				<a-button type="primary" @click="addEditBank('add')">
					添加
				</a-button>
			</div>
			<a-tree :show-line="true" :defaultExpandAll="true" :treeData="bankTreeList" v-if="bankTreeList.length">
				<template slot="custom" slot-scope="item">
					<div class="title-wrap">
						<span class="node-title">{{ item.title }} </span>
						<div>
							<a href="#" @click="addEditBank('add', item)">添加</a>
							<a-divider type="vertical" />
							<a href="#" @click="addEditBank('edit', item)">编辑</a>
							<a-divider type="vertical" />
							<a href="#" @click="deleteBank(item)">删除</a>
						</div>
					</div>
				</template>
			</a-tree>
		</div>

		<a-modal v-model="addEditShow" :title="isAdd ? '新增' : '修改信息'" :footer="null">
			<a-form-model ref="ruleForm" :model="info" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 14 }" style="margin: 0 auto; width: 80%">
				<a-row :gutter="24">
					<a-col :span="24">
						<a-form-model-item label="上级：" v-if="info.parent_name" >
							<a-input v-model="info.parent_name"  disabled/>
						</a-form-model-item>
						<a-form-model-item label="菜单名称：" prop="router_name">
							<a-input v-model="info.router_name"  />
						</a-form-model-item>
						<a-form-model-item label="路由地址：">
							<a-input v-model="info.router"  />
						</a-form-model-item>
						<a-form-model-item label="文件路径：">
							<a-input v-model="info.component"  />
						</a-form-model-item>
						<a-form-model-item label="排序：">
							<a-input-number style="width:100%" :min="1" v-model="info.sort_val"  />
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
			<div class="model-footer">
				<div class="content-btns" v-if="isAdd">
					<a-button type="primary" class="marginr20" @click="addBank()">
						保存
					</a-button>
					<a-button class="marginr20" @click="addEditShow = false">
						取消
					</a-button>
				</div>
				<div class="content-btns" v-if="!isAdd">
					<a-button type="primary" class="marginr20" @click="addBank('edit')">
						修改
					</a-button>
					<a-button class="marginr20"  @click="addEditShow = false">
						取消
					</a-button>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	import http from '../http'
	export default {
		data() {
			return {
				testData: [{
					id: 1,
					key: 1,
					title: "节点名字",
					scopedSlots: {
						title: "custom"
					}, // 自定义组件需要绑定
					children: [{
						id: 2,
						key: 2,
						title: "子节点名字",
						scopedSlots: {
							title: "custom"
						}
					}]
				}],
				bankTreeList: [],

				// 添加与编辑的数据
				info: {
					parent_id: 0,
					router_id: 0,
					router_name: '',
					router: '',
					component: '',
					sort_val: 1,
					is_menu: 1,
					is_open: 1,
					is_enable: 1,
				},
				rules: {
					router_name: [{
						required: true,
						message: '请输入菜单名称',
						trigger: 'blur'
					}, ],
				},

				addEditShow: false,
				isAdd: false,

				currentEditData: {}
			};
		},
		mounted() {
			this.getBankList()
		},
		methods: {
			getBankList() {
				http({
					method: 'post',
					url: '/api.router.list/',
				}).then((res) => {
					this.bankTreeList = this.dataDealwith(res.data)
				})
			},
			dataDealwith(treeData) {
				console.log(treeData)

				function setGrayNode(data) { //遍历树  获取id数组
					for (var i = 0; i < data.length; i++) {
						data[i] = {
							id: data[i].router_id,
							key: data[i].router_id,
							title: data[i].router_name,
							parent_id: data[i].parent_id,
							cate_sort: 0,
							scopedSlots: {
								title: "custom"
							},
							list: data[i].list || [],
							children: data[i].list || [],
							info: data[i]
						} //子节点传给另一个函数专门用来改数据
						if (data[i].list && data[i].list.length) { // 如果当前节点有子节点，就递归调用一次
							setGrayNode(data[i].list);
						}
					}
				}

				setGrayNode(treeData)
				console.log(treeData)
				return treeData;
			},

			addEditBank(type, data) {
				if (type == 'add') {
					this.info = {
						parent_id: 0,
						router_id: 0,
						router_name: '',
						router: '',
						component: '',
						sort_val:  1,
						is_menu: 1,
						is_open: 1,
						is_enable: 1,
					}
					this.isAdd = true
				} else {
					this.info = data.info
					console.log(this.info, 22)
					this.isAdd = false
				}
				if (type == 'add' && data) {
					this.info.parent_name = data.title
					this.info.parent_id = data.id
				}
				this.currentEditData = data
				this.addEditShow = true
			},
			addBank(type) {
				this.$refs.ruleForm.validate(valid => {
					if (valid) {
						http({
							method: 'post',
							url: '/api.router.add/',
							data: { ...this.info, parent_id: this.info.parent_id || 0
							}
						}).then((res) => {
							if (res.code == '200') {
								this.addEditShow = false
								this.$message.success(type == 'edit' ? '修改信息成功' : '添加成功');
								this.getBankList()
							} else {
								this.$message.error(res.data);
							}
						})
					} else {
						this.$message.warning('请检查是否已经输入必填项!');
						return false;
					}
				});
			},
			deleteBank(data) {
				this.$confirm({
					title: '是否删除路由?',
					okText: '确定',
					okType: 'danger',
					cancelText: '取消',
					onOk: () => {
						http({
							method: 'post',
							url: '/api.router.del/',
							data: {
								router_id: data.id
							}
						}).then((res) => {
							if (res.code == '200') {
								this.$message.success('删除路由成功');
								this.getBankList()
							} else {
								this.$message.error(res.data);
							}
						})
					}
				});
			}
		}
	};
</script>

<style lang="less">
	.title-wrap {
		display: flex;
		justify-content: space-between;
		width: 400px;

		div {
			span {
				margin-left: 10px;
				font-size: 18px;
			}
		}
	}

	.tree-wrap {
		background-color: #fff;
		padding: 10px;
		padding-bottom: 30px;
		margin-top: 25px;
		padding-left: 20px;

		.title-p {
			padding-top: 20px;
			font-weight: 700;
			font-size: 18px;
		}
	}

	.detf {
		display: flex;
		width: 430px;
		align-items: center;
		justify-content: space-between;
	}
</style>
